<template>
  <div v-if="cartEnvMessage" class="cart__env-message">
    <div class="cart__env-message__content" v-html="cartEnvMessage.content">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cartEnvMessage } from '../../store/modules/cart';

export default {
  name: 'CartEnvMessage',
  computed: {
    ...mapGetters({
      cartEnvMessage
    })
  }
};
</script>
