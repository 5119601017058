export enum ValidationStatus {
  NotStarted,
  Failed,
  Passed,
  Search
}

export enum InputType {
  Text,
  TextArea,
  Select
}

export interface Option {
  name: string;
  value: string;
}
