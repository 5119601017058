<template>
  <div>
    <div class="edit-address__cover">
      <div class="edit-address__dlg" @click="prevent" v-if="!confirming">
        <h1 class="header-biro">Edit address</h1>
        <Message messageType="alert" extras="compact" v-if="alert">
          {{alert}}
        </Message>
        <AddressWidget
          :allowManual="true"
          :updateAddressField="updateAddressField"
          :updateFullAddress="updateFullAddress"
          :errors="errors"
          :addressDetails="currentAddress"
          :tickValid="false"
          :idRoot="idRoot"
          :toggleManual="toggleManual"
        ></AddressWidget>
        <div class="edit-address__button-bar">
          <button @click="cancelEdit" class="wc-button wc-button--secondary">
            <span class="label">Cancel</span>
          </button>
          <button @click="showConfirm" class="wc-button wc-button--primary">
            <span class="label">Update</span>
          </button>
        </div>
      </div>
      <div class="edit-address__dlg edit-address__dlg-confirm" @click="prevent" v-if="confirming">
        <h1 class="header-biro">Update this account address?</h1>
        <p>
        This address change will be saved on this account. it will affect:
        </p>
        <ul>
          <li>Any other users on this account</li>
          <li>Any future orders placed using this address</li>
        </ul>
        <p>
          An email will be sent confirming your change of address
        </p>
        <div class="edit-address__button-bar">
          <button @click="hideConfirm" class="wc-button wc-button--secondary">
            <span class="label">Cancel</span>
          </button>
          <button @click="update" class="wc-button wc-button--primary">
            <span class="label">Update</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { valid, addressValidationRules } from '../../../helpers/validators';
import AddressWidget from '../AddressWidget/AddressWidget.vue';
import Message from '../Message/Message.vue';

export default {
  name: 'EditAddress',
  components: {
    AddressWidget,
    Message,
  },
  props: {
    cancelEdit: {
      type: Function,
    },
    saveAddressToEdit: {
      type: Function,
    },
    defaultAddress: {
      type: Object,
      default: () => {
        return {
          address1: '',
          postcode: '',
          state: '',
          city: '',
          address2: '',
          manual: 0
        };
      }
    },
    alert: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      currentAddress: {
        address1: '',
        state: '',
        city: '',
        address2: '',
        postcode: ''
      },
      tried: false,
      confirming: false,
    };
  },
  computed: {
    idRoot() {
      return 'editAddress';
    },
    errors() {
      let issues;
      if (this.tried) {
        return valid.fields(
          this.currentAddress,
          addressValidationRules,
          {}, // labels
          {}, // modified
          {}, // issues
        );
      }

      return { valid: true };
    },
  },
  methods: {
    prevent(ev) {
      ev.stopPropagation();
      ev.preventDefault();
    },
    tickValid() {
      return false;
    },
    disabled() {
      return false;
    },
    updateAddressField(fld) {
      return (ev) => {
        this.currentAddress[fld] = ev.target.value;
      };
    },
    updateFullAddress(address) {
      this.currentAddress = { ...this.currentAddress, ...address };
    },
    hideConfirm() {
      this.confirming = false;
    },
    showConfirm() {
      this.tried = true;
      if (this.errors.valid) {
        this.confirming = true;
      }
    },
    update() {
      this.cancelEdit();
      this.saveAddressToEdit(this.currentAddress, this.defaultAddress);
    },
    toggleManual() {
      this.currentAddress.manual = !this.currentAddress.manual;
    },
  },
};
</script>
