<template>
  <div :class="holderClass"
      @click="selectAction">
    <div class="nx-radio-item">
    <input
      type="radio"
      :name="name"
      :id="id"
      :value="value"
      :checked="selected"
      style="display:none;"
      />
      <div class="radio-btn__radio">
        <div class="radio-btn__radio__dot"></div>
      </div>
    </div>
    <label :for="id" class="button-radio_label">
      <slot>
      </slot>
    </label>
  </div>
</template>

<script>

export default {
  name: 'NxButtonRadio',
  components: {
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    selectAction: {
      type: Function,
      required: true,
    },
    showAsButton: {
      type: Boolean,
      default: true,
    },
  },
  data: function data() {
    return {
    };
  },
  computed: {
    holderClass() {
      if (!this.showAsButton) return 'button-radio-not-button';
      return {
        'button-radio': true,
        'button-radio-selected': this.selected,
      };
    },
  },
  methods: {},
};
</script>
