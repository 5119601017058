<template>
  <div v-if="iconClass">
    <updated-tip v-bind:message="tipText" tipType="hover smalltarget pre"></updated-tip>
    <span v-bind:class="iconClass"></span>
  </div>
</template>

<script>
import UpdatedTip from './UpdatedTip/index';

export default {
  name: 'ProductIcon',
  components: {
    'updated-tip': UpdatedTip,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconClass() {
      const iconMap = {
        // discontinued: 'cartitem_icon_discontinued',
        contract: 'cartitem_icon_contract',
        locally_made: 'cartitem_icon_locallymade',
        // bronze: 'cartitem_icon_bronze',
        clearance: 'cartitem_icon_clearance',
        non_returnable: 'cartitem_icon_non_returnable',
        earthsaver: 'cartitem_icon_earthsaver',
      };
      if (iconMap[this.name]) {
        return `cartitem_icon ${iconMap[this.name]}`;
      }
      return null;
    },
    tipText() {
      const textMap = {
        discontinued: 'This item is discontinued and will not be restocked',
        contract: 'These products are contracted items agreed between your organisation and Winc.',
        locally_made: 'These products are Australian Made.',
        bronze: 'This is a bronze item. It may take longer to deliver',
        clearance: 'This item is no longer part of our in stock range and is only available while stocks last.',
        non_returnable: 'This product is not eligible for change of mind returns.\nSee our Returns Policy.',
        earthsaver: 'As part of our commitment to sustainability, we have developed the EarthSaver\nclassification to assist our customers in identifying products that meet \nenvironmentally preferable product criteria.',
      };
      return textMap[this.name];
    },
  },
};
</script>
