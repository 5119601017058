<template>
  <div class="cartitem__quantity">
    <div class="cartitem__quantity-inner">
      <updated-tip
        v-bind:message="tipMessage"
        v-bind:tipType="tipType"
        v-if="tipMessage"
      />
      <button
        class="cartitem__quantity-decreaser"
        type="button"
        aria-label="decrease quantity"
        v-on:click="decrement"></button>
      <input
        type="number"
        step="1"
        pattern="\d*"
        v-bind:min="minQty"
        max = "999999"
        v-bind:value="value"
        @input='manualEdit($event)'
        aria-label="Item quantity"
        class="cartitem__quantity-input"
        v-bind:id="fieldId" />
      <button
        class="cartitem__quantity-increaser"
        type="button"
        aria-label="increase quantity"
        v-on:click="increment"></button>
    </div>
  </div>
</template>
<script>
import UpdatedTip from '@/components/common/UpdatedTip/index';

export default {
  name: 'CartItemQuantity',
  props: {
    value: {
      type: Number,
      required: true,
    },
    tipMessage: {
      type: String,
      required: false,
    },
    tipType: {
      type: String,
      required: false,
    },
    updateQuantity: {
      type: Function,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    minQty: {
      type: Number,
      required: true,
    },
    maxQty: {
      type: Number,
      required: false,
    },
  },
  components: {
    'updated-tip': UpdatedTip,
  },
  computed: {
  },
  methods: {
    increment() {
      this.editValue(this.value + 1);
    },
    decrement() {
      this.editValue(this.value - 1);
    },
    manualEdit(evt) {
      const newValue = evt.target.value.toString().replace(/\D+/, '');
      if (newValue === '') return this.editValue(0, evt.target);
      this.updateQuantity(parseInt(newValue, 10));
      return parseInt(newValue, 10);
    },
    editValue(value, target) {
      let newValue = value;
      if (newValue < this.minQty) newValue = this.minQty;
      const max_qty = this.maxQty || 999999;
      if (newValue > max_qty) newValue = max_qty;
      if (target) target.value = newValue;
      this.updateQuantity(newValue);
    },
  },
};
</script>
