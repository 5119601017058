const View = {
  functional: true,
  render(createElement, { props: {
    message,
    classes,
    icon,
    handleClick,
    showTip,
  } }) {
    const tick = icon
      ? <span class="updated_tip__icon"></span>
      : '';
    const tipClass = `updated_tip ${classes} noprint`;
    const tip = showTip
      ? <div class={tipClass} onClick={handleClick}>
          <div class="updated_tip__inner">
            {tick}
            {createElement('span', {
              class: '',
              domProps: {
                innerHTML: message
              }
            })}
          </div>
          <div class="updated_tip__arrow"></div>
        </div>
      : '';
    return (
      tip
    );
  },
};

export default View;
