<template>
  <div  v-bind:class="finalMainClass" v-if="shouldDisplay">
      <div class="cart__total-label">{{label}}
        <div class="cart__total-sublabel">
          {{subLabel}}
        </div>
      </div>
      <div class="cart__total_tip" v-if="sosc">
        <div class="cart__total_tip_contents">
          <message messageType="leaf">
            {{sosc.field}}
          </message>
        </div>
        <div class="cart__total_tip_icon"></div>
      </div>
      <div  v-bind:class="finalTotalClass">
        {{number}}
      </div>
  </div>
</template>

<script>
import message from '../common/Message/Message.vue';

export default {
  name: 'OrderDetailsTotal',
  components: {
    message,
  },
  props: {
    number: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    subLabel: {
      type: String,
      required: false,
    },
    mainClass: {
      type: String,
      required: false,
      default: '',
    },
    numberClass: {
      type: String,
      required: false,
      default: '',
    },
    sosc: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    shouldDisplay() {
      return this.number !== '$0.00';
    },
    finalMainClass() {
      return `cart__total ${this.mainClass}`;
    },
    finalTotalClass() {
      return `cart__total-number ${this.numberClass}`;
    },
    gstMessage() {
      return window.nxDatalayer.global.show_gst
        ? 'inc GST' : 'ex GST';
    },
  },
};
</script>
