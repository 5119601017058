<template>
  <div v-bind:class="messageClass">
    <div v-bind:class="iconClass">
    </div>
    <div class="inline_message__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    messageType: {
      type: String,
      required: true,
    },
    extras: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    iconClass() {
      return `inline_message__icon inline_message__icon-${this.messageType}`;
    },
    messageClass() {
      const all = `${this.messageType} ${this.extras}`.trim();
      const extraClasses = `inline_message-${all.split(' ').join(' inline_message-')}`;
      return `inline_message ${extraClasses}`;
    },
  },
};
</script>
