import { isEmpty } from '@/helpers/util';
import { tomorrowString } from './ShippingAddress/OrderScheduling/helpers';

// @ts-ignore
export const isRetailUser = () => nxDatalayer?.global?.channel === 'retail';
// @ts-ignore
export const isGenericUser = () => nxDatalayer?.global?.is_generic;

export const apiDateString = (date: string) =>
  isEmpty(date) ? '' : new Date(date).toISOString().substr(0, 10);

export const objectEveryValueIsEmpty = (obj) => Object.keys(obj).every((key) => isEmpty(obj[key]));
export const objectEveryValueIsNotEmpty = (obj) =>
  Object.keys(obj).every((key) => !isEmpty(obj[key]));

export const contactInfoID = 'chk-part-contact';
export const shippingAddressID = 'chk-part-address';
export const paymentID = 'chk-part-payment';
export const orderDetailsTileID = 'nx-checkout-id-order-details-tile';
export const scrollToView = (element) => {
  const headerOffset = 100;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - headerOffset;
  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth'
    });
  } else {
    window.scrollBy(0, offsetPosition);
  }
};
export const isInView = (elem) => elem.getBoundingClientRect().top <= 245;

const tomorrow = tomorrowString();
export const scheduleDefault = {
  schedule: {
    recurrencetype: 'O',
    weekly_frequence: 1,
    weekly_friday: false,
    weekly_monday: false,
    weekly_saturday: false,
    weekly_sunday: false,
    weekly_thursday: false,
    weekly_tuesday: false,
    weekly_wednesday: false,
    startdate: tomorrow,
    enddate: tomorrow,
    enddate_type: 'N',
    total_occurrences: 1,
    monthly_type: 'D',
    monthly_date: 1,
    monthly_frequence_of_day: 1,
    monthly_nthday_of_week: 1,
    monthly_day_of_week: 1,
    monthly_frequence_of_week: 1,
    skipnext: false, // Switch off skipnext when setting a new schedule.
  },
  forwarddate: null,
};
