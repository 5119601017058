<template>
  <div class="nx-radio-item" :for="option.value"  @click="checkRadio">
    <input
      type="radio"
      :value="option.value"
      :id="option.value"
      :name="this.$parent.name"
      :checked="isChecked(option.value)"
    />
    <div class="radio-btn__radio">
        <div class="radio-btn__radio__dot"></div>
    </div>
    <div for="nx-ritem" v-if="option.tip">
      <updated-tip :message="option.tip" tipType="hover info"/>
      <label for="nx-ritem-label">
      {{ option.label }}
      </label>
    </div>
    <div for="nx-ritem" v-else>
      <label>{{ option.label }}</label>
    </div>

  </div>
</template>

<script>
import UpdatedTip from './UpdatedTip/index';

export default {
  name: 'NxRadioButton',
  components: {
    UpdatedTip
  },
  props: {
    option: {
      required: true,
      type: Object,
    },
  },
  methods: {
    checkRadio(event) {
      this.$parent.$emit('input', this.option);
    },
    isChecked(value) {
      return (value === this.$parent.checked_option.value);
    },
  },
};
</script>
<!-- For Development purpose only, comment it for prod build -->
<!--
<style lang="scss">
@import "../../../../build/sass/components/common/_nx_radio_button.scss";
</style>
-->
