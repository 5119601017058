<template>
  <div>
    <div class="leftright spaceddiv">
      <div>
        <span v-html="iconForFile" />
        <a :href="attachment.location" class="quote_file-link" target="_blank">
          {{attachment.filename}}
        </a>
      </div>
      <a href="javascript:void(0)" @click="deleteFile" v-if="!readOnly">
        <i class="fa fa-trash" aria-hidden="true"></i>
      </a>
    </div>
    <hr class="quote_hr" v-if="showHr" />
  </div>
</template>

<script>

const icons = {
  PDF: '<i class="fa fa-file-pdf-o" aria-hidden="true"></i>',
  DOC: '<i class="fa fa-file-word-o" aria-hidden="true"></i>',
  DOCX: '<i class="fa fa-file-word-o" aria-hidden="true"></i>',
  XLS: '<i class="fa fa-file-excel-o" aria-hidden="true"></i>',
  XLSX: '<i class="fa fa-file-excel-o" aria-hidden="true"></i>',
  PNG: '<i class="fa fa-image" aria-hidden="true"></i>',
  JPG: '<i class="fa fa-image" aria-hidden="true"></i>',
  JPEG: '<i class="fa fa-image" aria-hidden="true"></i>',
  FILE: '<i class="fa fa-file" aria-hidden="true"></i>',
};

export default {
  name: 'Attachment',
  props: {
    attachment: {
      type: Object,
    },
    delete: {
      type: Function,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showHr: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconForFile() {
      const { filename } = this.attachment;
      const n = filename.lastIndexOf('.');
      const type = filename.substring(n + 1).toUpperCase();
      return icons[type] || icons.FILE;
    },
  },
  methods: {
    deleteFile() {
      this.delete();
    },
  },
};
</script>
