import axios from 'axios';
import { makeToast } from '../../../helpers/helpers';
import { redirectOnAuthFail } from '../../common/Auth';
/** ******************************
 * helpers
 ****************************** */

export interface ApproverRequest {
  apps: {
    appid: string;
    uid: string;
  }[];
}

const postOrderApprovers = (url, data: ApproverRequest) => {
  return axios.post(url, data)
    .catch((error) => {
      redirectOnAuthFail(error);
      makeToast({
        title: 'Approver Details',
        text: 'There was a problem saving the approver selections on your order.',
        type: 'error',
        duration: -1,
      });
    });
};

export default postOrderApprovers;
