import { Item } from '@/components/Checkout/OrderSummary/store';

interface ItemInResponse {
  packsize: string;
  item_cost: {
    base: { mills: number; string: string };
  };
  primary_image: {
    thumb: string;
  };
  quantity: number;
  description: string;
  prodcode: string;
  price: string;
  has_assembly: boolean;
  single_use_plastic: number;
  assembly_item: {
    description: string;
    total_cost: any;
    cost: any;
  };
  show_etd: any;
  etd_sort: any;
  is_preorder: boolean;
  ltoos_display_date: string;
}
const defaultPrice = (price) => (price ? price.base : { mills: 0, string: 'N/A' });
const defaultAssemblyDescription = (has_assembly, assembly_item) => (has_assembly ? assembly_item.description : '');
const defaultAssemblyTotalPrice = (has_assembly, assembly_item) => (has_assembly ? assembly_item.total_cost.total.string : '');
const defaultAssemblyCost = (has_assembly, assembly_item) => (has_assembly ? assembly_item.cost : '');
export const getItems = (items: ItemInResponse[]): Item[] =>
  items.map(
    ({
      packsize,
      item_cost,
      price,
      primary_image,
      description,
      prodcode,
      quantity,
      has_assembly,
      single_use_plastic,
      assembly_item,
      show_etd,
      etd_sort,
      is_preorder,
      ltoos_display_date,
    }) => ({
      packSize: packsize,
      cost: defaultPrice(item_cost),
      price,
      imgUrl: primary_image?.thumb,
      description,
      prodCode: prodcode,
      quantity,
      hasAssembly: has_assembly,
      single_use_plastic,
      assemblyDescription: defaultAssemblyDescription(has_assembly, assembly_item),
      assemblyTotalPrice: defaultAssemblyTotalPrice(has_assembly, assembly_item),
      assemblyCost: defaultAssemblyCost(has_assembly, assembly_item),
      show_etd,
      etd_sort,
      is_preorder,
      ltoos_display_date,
    })
  );
