<template>
  <div class="edit-address__cover">
    <span>
      <p>
        <i class="fa fa-spinner rotate" aria-hidden="true" style="color:white;font-size:20px;">
        </i>
      </p>
    </span>
  </div>
</template>
<script>

export default {
  name: 'NxSpinner',
};
</script>
