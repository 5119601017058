<template>
  <div v-bind:class="stickyClass">
    <div v-bind:class="cartDetailsClass">
      <h2 v-bind:class="cartDetailsHeaderClass('cod')" @click="toggle">
          <span>Current Order Details</span>
      </h2>
      <hr class="cart__details-hr" />
      <h2 v-bind:class="cartDetailsHeaderClass('ord')">
          Order Number: {{orderid}}
      </h2>
      <div class="cart__details-contents">
        <account-picker v-if="showAccountPicker" v-bind:readOnly="!isEditableAccountPicker" />
        <slot name="customer-reference"></slot>
        <order-details-totals v-if="showTotals" :containedBy="containedBy" />
        <slot name="cta"></slot>
      </div>
      <div class="cart__details-footer">
        <slot name="coupons">
        <div class="cart_coupons_entered" v-for="coupon in coupons" v-bind:key="coupon.code" >
          <span class="cart_coupons_icon_active"></span>
          <span class="cart_coupons_entered-text">{{coupon.code}} - {{coupon.name}}</span>
        </div>
        </slot>
      </div>
    </div>
    <div  class="noprint">
      <slot name="continue-shopping">
        <p>
          <a href="/" class=" major_link ">
            <i class="fa fa-angle-left"></i>
            <span>Continue Shopping</span>
          </a>
        </p>
      </slot>
    </div>
    <cart-env-message></cart-env-message>
    <div class="cart__below_details noprint">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderDetailsTotals from './OrderDetailsTotals.vue';
import OrderDetailsAccountPicker from './OrderDetailsAccountPicker.vue';
import CartEnvMessage from './CartEnvMessage.vue';
import {
  orderid,
  cost,
  readOnly,
  coupons,
} from './OrderDetailsStore';

export default {
  name: 'OrderDetails',
  data() {
    return {
      collapsed: false,
    };
  },
  props: {
    editableOrderDetails: {
      type: Boolean,
      required: true,
    },
    isSticky: {
      type: Boolean,
      required: false,
    },
    containedBy: {
      type: String,
      default: '',
    },
  },
  components: {
    'order-details-totals': OrderDetailsTotals,
    'account-picker': OrderDetailsAccountPicker,
    CartEnvMessage
  },
  computed: {
    ...mapGetters({
      orderid,
      cost,
      readOnly,
      coupons,
    }),
    stickyClass() {
      return this.isSticky
        ? 'cart__sticky'
        : '';
    },
    displayPrices() {
      return window.nxDatalayer.global.displayprices;
    },
    showTotals() {
      return this.cost && this.displayPrices;
    },
    cartDetailsClass() {
      if (this.collapsed) {
        return 'cart__details cart__details-collapsed';
      }
      return 'cart__details';
    },
    isEditableAccountPicker() {
      return this.editableOrderDetails && !this.readOnly;
    },
    showAccountPicker() {
      return window.nxDatalayer.global.authenticated;
    },
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
    cartDetailsHeaderClass(mode) {
      let headerCss = 'cart__details-header';

      if (mode === 'cod') {
        headerCss += ' cart__details_cod noprint';
      } else {
        headerCss += ' cart__details_ordernum';
      }

      return headerCss;
    },
  },
};
</script>
