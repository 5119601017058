import { trueFunc } from 'boolbase';
import View from './view';

const UpdatedTip = {
  name: 'UpdatedTip',
  props: {
    message: { type: String, required: true },
    tipType: { type: String, required: false, default: '' },
  },
  data: function data() {
    return {
      showTip: true,
    };
  },
  methods: {
    handleClick() {
      this.showTip = false;
    },
  },
  render() {
    const { message, handleClick, showTip } = this;
    const types = this.tipType.split(' ');
    const classes = types.reduce((acc, el) => {
      if (el === '') { return acc; }
      return `${acc} updated_tip-${el}`;
    }, '');
    let icon;
    if (types.includes('success') || types.includes('error') || types.includes('info')) {
      icon = true;
    }
    return (
      <View
        message={message}
        classes={classes}
        icon={icon}
        handleClick={handleClick}
        showTip={showTip}
      />
    );
  }
};

export default UpdatedTip;
