import Vue from 'vue';
import UpdatedTip from '../UpdatedTip';
import { InputType, ValidationStatus, Option } from './types';
import Label from './Label';
import Input from './Input';
import Icon from './Icon';

interface Props {
  label: string;
  id: string;
  type: number;
  fieldtype: string;
  name: string;
  handleChange: () => void;
  validationStatus: number;
  value: string;
  error: string;
  placeholder: string;
  inputClassName: string;
  className: string;
  disabled: boolean;
  options: Option[];
  maxLength: number;
  tooltipHelpMsg;
  showValid: boolean;
  showDollar: boolean;
  handleBlur: () => void;
}

const View = Vue.extend<Props>({
  functional: true,
  render(
    createElement,
    {
      props: {
        label,
        id,
        type = InputType.Text,
        fieldtype,
        handleChange,
        validationStatus,
        value,
        tooltipHelpMsg,
        error,
        placeholder,
        inputClassName,
        className,
        disabled,
        options = [],
        maxLength,
        showDollar,
        handleBlur,
      }
    }
  ) {
    const tipMsg = error || tooltipHelpMsg;
    const tipType = error
      ? 'error'
      : 'info hover';
    return (
      <div class={`checkout__input-group ${className}`}>
        {/*
 // @ts-ignore */}
        {label && <Label id={id} label={label} hasError={!!error} />}
        {/*
 // @ts-ignore */}
        {tipMsg && <UpdatedTip message={tipMsg} tipType={tipType} />}
        {/*
 // @ts-ignore */}
        <Input
          id={id}
          type={type}
          fieldtype={fieldtype}
          value={value}
          handleChange={handleChange}
          placeholder={placeholder}
          inputClassName={inputClassName}
          disabled={disabled}
          options={options}
          maxLength={maxLength}
          validationStatus={validationStatus}
          showDollar={showDollar}
          handleBlur={handleBlur}
        />
        {showDollar && <span class="checkout__input-group__dollar">$</span>}
        {/*
 // @ts-ignore */}
        <Icon validationStatus={validationStatus} />
      </div>
    );
  }
});

export default View;
