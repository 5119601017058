import Vue from 'vue';
import { ValidationStatus } from '../types';

interface Props {
  validationStatus: ValidationStatus;
}
const Icon = Vue.extend<Props>({
  functional: true,
  render(createElement, { props: { validationStatus } }) {
    switch (validationStatus) {
      case ValidationStatus.Failed: {
        return (
          <div class="checkout__input-group__input-icon checkout__input-group__input-icon--error" />
        );
      }
      case ValidationStatus.Passed: {
        return (
          <div class="checkout__input-group__input-icon checkout__input-group__input-icon--success" />
        );
      }
      case ValidationStatus.Search: {
        return (
          <div class="checkout__input-group__input-icon input-icon--search" />
        );
      }
      default: {
        return <div class="checkout__input-group__input-icon"></div>;
      }
    }
  }
});

export default Icon;
