<template>
  <div class="nx-radio-item addressItem"
    v-bind:class="{ 'addressItem-checked': isChecked }"
    :for="itemId">
    <div class=" addressItem-vcentred">
      <input
        type="radio"
        :value="itemId"
        :id="name + '_' + itemId"
        :name="name"
        :checked="isChecked"
      />
    <div class="radio-btn__radio" v-if="canSelect" @click="handleClick">
        <div class="radio-btn__radio__dot"></div>
    </div>
    </div>
    <div class="addressItem__slot-contents" @click="handleClick">
      <slot></slot>
    </div>
    <div class="addressItem__edit-button addressItem-vcentred"
      v-if="canEdit" @click="editFunction">
      <span>Edit</span>
    </div>
  </div>

</template>
<script>

export default {
  name: 'AddressListItem',
  components: {
  },
  props: {
    isChecked: {
      type: Boolean,
    },
    handleClick: {
      type: Function,
      default: () => {},
    },
    canEdit: {
      type: Boolean,
    },
    editFunction: {
      type: Function,
    },
    canSelect: {
      type: Boolean,
    },
    itemId: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  conputed: {
    itemClass() {
      return this.isChecked
        ? 'nx-radio-item addressItem addressItem-checked'
        : 'nx-radio-item addressItem';
    },
  },
  methods: {
    edit() {
      this.editFunction();
    },
  }
};
</script>
