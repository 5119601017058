<template>
  <div :class="customClass()">
    <NxRadioButton
      :name="name"
      v-for="option in options"
      :option="option"
      :key="option.name"
    />
  </div>
</template>

<script>
import NxRadioButton from './NxRadioButton.vue';

export default {
  name: 'NxRadioButtonGroup',
  components: {
    NxRadioButton,
  },
  data: function data() {
    return {
      default_option: this.options[0],
    };
  },
  props: {
    dclass: {
      type: String,
      default: 'nx-radio-button-group'
    },
    name: String,
    options: {
      required: true,
      type: Array,
    },
    checked_option: {
      required: true,
      type: Object,
    },
  },
  methods: {
    customClass() {
      return this.dclass;
    },
  }
};
</script>
