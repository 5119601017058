import Vue from 'vue';

// *****************field update statuses************************
// changed
export const FIELD_UPDATE_REQUIRED = 'fieldUpdateRequired';
// sent to server
export const FIELD_UPDATE_PENDING = 'fieldUpdatePending';
// pending response from server, but changed in the meantime
export const FIELD_UPDATE_OVERWRITE = 'fieldUpdateOverwite';
// firld updated correctly
export const FIELD_UPDATE_SUCCESS = 'fieldUpdateSuccess';
// field not updated
export const FIELD_UPDATE_FAILED = 'fieldUpdateFailed';
// ***************************************************************

// helper to get url
export function apiUri(type = 'manage') {
  const urlParams = new URLSearchParams(window.location.search);
  const mock = urlParams.get('mock');
  if (mock) {
    return `/mocks/${mock}.json`;
  }
  const quoteno = urlParams.get('quoteno');
  const token = urlParams.get('token');
  if (!(quoteno && token)) return 'missing params';
  return `/rest-quote-action/${token}/${quoteno}/${type}`;
}
// serverErrors and checkServerErrors are used to check the
// returned data for server-side errors
export function serverErrors(data) {
  if (!data) {
    return 'No data returned from server';
  }
  const { issues } = data;
  const fatalaties = {
    sap_server_error: 1,
    nx_client_error: 1,
    system_generic: 1,
  };
  let ret = '';
  issues.forEach((issue) => {
    if (issue.shortcode === '412') {
      ret = 'etag issue';
    } else if (fatalaties[issue.code]) {
      ret = 'SAP server error';
    }
  });
  return ret;
}
export function checkServerErrors(data, commit) {
  const err = serverErrors(data);
  if (err) {
    commit('setBackendError', err);
    commit('toggleSpinner', '');
    commit('toggleDialog', 'ServerError');
    return true;
  }
  return false;
}

export const headerFields = {
  customerreference: 'quote_details',
  delete_attachment: 'quote_details',
  deliverynote: 'shipping_details',
  deliver_by: 'shipping_details',
  address1: 'shipping_details',
  address2: 'shipping_details',
  city: 'shipping_details',
  state: 'shipping_details',
  postcode: 'shipping_details',
  contactemail: 'shipping_details',
  contactname: 'shipping_details',
  contactphone: 'shipping_details',
};

export const etagFields = {
  customerreference: 'etag',
  deliverynote: 'deliverynote_etag',
  address1: 'address_etag',
  address2: 'address_etag',
  city: 'address_etag',
  state: 'address_etag',
  postcode: 'address_etag',
  contactemail: 'noedit',
  contactname: 'contactname_etag',
  contactphone: 'contactphone_etag',
  quantity: 'etag',
  linenote: 'linenote_etag',
  customer_comments: 'customer_comments_etag',
  added_attachments: 'etag',
  deleted_attachments: 'etag',
  selected: 'etag',
  delete_attachment: 'etag',
};

// if a field is updated, normally it's set to required
// but if it's already pending a server response, it's set to overwrite
export const setRequiredOrOverWrite = (updateStatus, field) => {
  if (updateStatus[field] === FIELD_UPDATE_OVERWRITE) {
    // leave it
  } else if (updateStatus[field] === FIELD_UPDATE_PENDING) {
    Vue.set(updateStatus, field, FIELD_UPDATE_OVERWRITE);
  } else {
    Vue.set(updateStatus, field, FIELD_UPDATE_REQUIRED);
  }
};

// scrolls to a field when given the id
export function scrollTo(fieldId) {
  const el = document.getElementById(fieldId);
  if (!el) return;
  const bodyRectY = document.body.getBoundingClientRect().y;
  const y = el.getBoundingClientRect().y - bodyRectY - 100;
  window.scrollTo(0, y);
  el.focus();
}

export const adobeProducts = (items, rejectReason = '') => {
  // Category (optional): The product category.
  // Product name (required): The name of the product.
  // Quantity (optional): How many of this product is in the cart.
  // Price (optional): The total price of the product as a decimal.
  // Events (optional): Events tied to the product. Delimit multiple events with a pipe (|).
  // eVars (optional): Merchandising eVars tied to the product.
  const ret = [];
  const allSelected = items.every((item) => item.selected);
  items.forEach((item) => {
    let eVar88 = '';
    if (rejectReason) {
      eVar88 = rejectReason;
    } else if (allSelected) {
      eVar88 = 'accepted all';
    } else {
      eVar88 = item.selected ? 'accepted partial' : 'rejected partial';
    }
    const price = item.linetotal.replace('$', '');
    const events = `event144=${price}|event145=${item.quantity}`;
    const eVars = `eVar88=${eVar88}`;
    ret.push(`;${item.prodcode};;;${events};${eVars}`);
  });
  return ret.join(',');
};
