function filterText(data, filter, fullSearch = false) {
  // take care of nulls
  if (typeof data === 'undefined' || data === null) {
    return false;
  }

  // row value
  const dataue = String(data).toLowerCase();

  // search term
  const searchTerm = filter.toLowerCase();

  // comparison
  return fullSearch ? dataue === searchTerm : (dataue.indexOf(searchTerm) > -1);
}

function dateParse(dateString) {
  const parts = dateString.split('-');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1] - 1, 10);
  const year = parseInt(parts[2], 10);
  return new Date(year, month, day);
}

function filterDateRange(data, filters) {
  const creation_date = dateParse(data.created_date);

  if (filters.from_date != null && typeof filters.from_date === 'object') {
    // return not matched if invoice creation date is less than from_date
    if (creation_date < filters.from_date) {
      return false;
    }
  }

  if (filters.to_date != null && typeof filters.to_date === 'object') {
    // return not matched if invoice creation date is more than to_date
    if (creation_date > filters.to_date) {
      return false;
    }
  }

  return true;
}

const filterInvoiceData = (data, filters) => {
  let matched = false;
  const text_filter_cols = ['document_number', 'order_number', 'order_reference', 'account_number'];

  for (let i = 0; i < text_filter_cols.length; i += 1) {
    const col = text_filter_cols[i];

    // match filter text first
    matched = filterText(
      data[col],
      filters.text
    );

    if (matched) break;
  }

  // filter out non overdue records to match overdue data
  if (matched) {
    if (filters.overdue && !data.is_overdue) {
      return false;
    }
  }

  // filter out of date range records to match record within date range
  if (matched) {
    matched = filterDateRange(
      data,
      filters
    );
  }

  return matched;
};

export {
  filterInvoiceData,
};
