<template>
  <div class="edit-address__cover">
    <div class="edit-address__failed-contents">
      <h2 class="header2 header-biro">Address change request received</h2>
      <p>
        <slot />
      </p>
      <div class="edit-address__button-bar">
        <button @click="hideFailure" class="wc-button wc-button--primary">
          <span class="label">Continue</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'UpdateFailed',
  props: {
    hideFailure: {
      type: Function,
    },
  },
};
</script>
