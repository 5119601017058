export const considerGst = (price) => {
  if (window.nxDatalayer.global.show_gst) {
    return price.total.string;
  }
  return price.base.string;
};

export const countItem = (item) => {
  return item.has_assembly
    ? 2
    : 1;
};
