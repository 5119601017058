export const invoiceEndpoint = '/invoice_endpoint';
export const emailInvoiceEndpoint = '/invoice_endpoint/email';
export const ccInvoiceEndpoint = '/invoice_endpoint/cc';
export const cartEndpoint = '/cart_endpoint';
export const etdEndpoint = '/etd_endpoint';
export const kyleEtdEndpoint = '/kyle-etd_endpoint';
export const glcodesEndpoint = '/_costassign-match';
export const orderDetailsEndpoint = '/order-details';
export const approversEndpoint = '/o';
export const existingAddressesEndpoint = '/mock-existing-addresses/';
export const creditCardEndpoint = '/creditCardEndpoint';
export const addressListEndpoint =
  'https://uatapi.netxpress.biz/api/addressify/addresspro/autocomplete';
export const addressDetailsEndpoint = 'https://uatapi.netxpress.biz/api/addressify/addresspro/info';
