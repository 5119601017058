<template>
  <div class="cart__totals">
    <div v-if="showCharges">
      <cart-total
        v-bind:number="subtotal"
        label="Subtotal"
        v-bind:sub-label="gstMessage"
        number-class="cart__total-subtotal" />
      <hr class="cart__separator-light" />
      <cart-total
        v-bind:number="coupons"
        label="Coupons" />
      <cart-total
        v-bind:number="charge"
        v-bind:label="chargeLabel"
        v-bind:sosc="SOSC" />
      <cart-total
        v-bind:number="freight"
        :label="freightLabel" />
      <cart-total
        v-bind:number="caf"
        label="CAF" />
      <cart-total
        v-bind:number="orderDiscount"
        label="Discount" />
      <hr class="cart__separator" />
    </div>
    <cart-total
      v-bind:number="orderTotal"
      :label="totalLabel"
      v-bind:sub-label="gstMessage"
      main-class=" cart__total-blue cart__total-container"
      number-class="cart__total-total"  />
    <div class="cart__totals_gst">
      <div class="">
        (GST {{gstOnTotal}})
      </div>
      <div class="" v-if="!showGST">
        Total inc GST {{totalIncludingGst}}
      </div>
    </div>
    <div class="cart__total-hint" v-if="zoneFreightHintShow">
      Total freight will be calculated at checkout
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SOSC,
  cost,
  simulationError,
} from './OrderDetailsStore';
import CartTotal from './OrderDetailsTotal.vue';
import {
  considerGst,
} from '../../helpers/helpers';

export default {
  name: 'OrderDetailsTotals',
  components: {
    'cart-total': CartTotal,
  },
  props: {
    containedBy: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      SOSC,
      cost,
      simulationError,
    }),
    showGST() {
      return window.nxDatalayer.global.show_gst;
    },
    gstMessage() {
      return this.showGST ? 'Including GST' : 'Excluding GST';
    },
    orderTotal() {
      return considerGst(this.cost.total);
    },
    orderSubTitle() {
      return '';
    },
    orderDiscount() {
      return considerGst(this.cost.orderdiscount);
    },
    coupons() {
      return considerGst(this.cost.coupons);
    },
    // Small Order Service Charge
    charge() {
      return considerGst(this.cost.charge);
    },
    chargeLabel() {
      return this.cost.charge.label || 'Charge';
    },
    // Zone Freight Service Charge
    zoneFreightCharge() {
      return considerGst(this.cost.zone_freight);
    },
    zoneFreightChargeLabel() {
      return this.cost.zone_freight.label || 'Total freight';
    },
    totalLabel() {
      if (this.isCheckout) {
        // In checkout show Total always
        return 'Total';
      }

      // In Cart show Total or SubTotal
      return this.showCharges ? 'Total' : 'Subtotal';
    },
    zoneFreightHintShow() {
      return this.cost?.zone_freight?.show_hint;
    },
    // If show_hint is not set then we are in checkout
    isCheckout() {
      return this.containedBy === 'checkout';
    },
    caf() {
      return considerGst(this.cost.caf);
    },
    freight() {
      if (this.isCheckout && !this.simulationError && this.orderTotal === this.subtotal) {
        return 'Free';
      }
      return considerGst(this.cost.freight);
    },
    freightLabel() {
      return this.cost.freight.label || 'Total Freight';
    },
    subtotal() {
      return considerGst(this.cost.items);
    },
    showCharges() {
      if (this.orderTotal !== this.subtotal) {
        return true;
      }
      if (this.isCheckout && !this.simulationError) {
        return true;
      }
      return false;
    },
    gstOnTotal() {
      return this?.cost?.total?.tax?.string;
    },
    totalIncludingGst() {
      return this?.cost?.total?.total?.string;
    },
  },
};
</script>
