import axios from 'axios';

/** ******************************
 * helpers
 ****************************** */
interface Res {
  AddressLine1: string;
  AddressLine2: string;
  StreetLine: string;
  Suburb: string;
  State: string;
  BuildingName: string;
  LevelTypeFull: string;
  LevelNumber: string;
  UnitTypeFull: string;
  UnitNumber: string;
  Postcode: string;
  Valid: true;
}

const getBuildingSuiteFloor = (
  buildingName: string,
  levelNumber: string,
  levelType: string,
  unitNumber: string,
  unitType: string
): string => [
  unitType,
  unitNumber,
  levelType,
  levelNumber,
  buildingName
].filter((elem) => typeof elem !== 'undefined' && elem !== null && elem !== '')
  .join(' ');

const cutFromAddressLine = (
  streetLine: string,
  buildingSuiteFloor: string
): string => {
  const regexBSF = new RegExp(`^${buildingSuiteFloor} `);
  return streetLine.replace(regexBSF, '');
};

// Note how the incoming addressLine2 maps to our addressLine1 ("Street Address")
// Also in some situations we need to use the streetLine for our addressLine1,
// if both incoming addressLines are empty.
//
// Examples - incoming fields => our form fields
//
// 12 KOOKABURRA AVE, CULLACABARDEE WA 6067
// addressline1: null
// addressline2: 12 KOOKABURRA AVE
// streetaddress1: 12 KOOKABURRA AVE
//
// => Building/Suite/Floor: ''
// => Street Address: 12 KOOKABURRA AVE
//
// LEVEL 1, 101 LILLIANA BVD, CARRUM DOWNS VIC 3201
// addressline1: null
// addressline2: null
// streetaddress1: LEVEL 1 101 LILLIANA BVD
// levelTypeFull: LEVEL
// levelNumber: 1
//
// => Building/Suite/Floor: LEVEL 1
// => Street Address: 101 LILLIANA BVD
//
// FLOOR 2, STAFFORD HEIGHTS QLD 4053
// addressline1: null
// addressline2: null
// streetaddress1: FLOOR 2
//
// => Building/Suite/Floor: ''
// => Street Address: FLOOR 2
// (we don't want the Building/Suite/Floor to have 'FLOOR 2' because that would
//  mean our required field 'Street Address' is empty)
//
// UNIT 1 1 SACHA TCE, TERRIGAL NSW 2260
// addressline1: UNIT 1
// addressline2: 1 SACHA TCE
// streetaddress1: UNIT 1 1 SACHA TCE
// => Building/Suite/Floor: UNIT 1
// => Street Address: 1 SACHA TCE
//
// SUITE 2 LEVEL 2, 75 TALAVERA RD, MACQUARIE PARK NSW 2113
// => Building/Suite/Floor: SUITE 2 LEVEL 2
// => Street Address: 75 TALAVERA RD
//
const translateAddress = ({
  AddressLine1: addressLine1,
  AddressLine2: addressLine2,
  StreetLine: streetLine,
  BuildingName: buildingName,
  LevelNumber: levelNumber,
  LevelTypeFull: levelType,
  UnitNumber: unitNumber,
  UnitTypeFull: unitType,
  Suburb: suburb,
  State: state,
  Postcode: postcode
}: Res) => {
  const usingStreetLine = !addressLine2;
  const buildingSuiteFloor = (
    addressLine1 || getBuildingSuiteFloor(
      buildingName, levelNumber, levelType, unitNumber, unitType
    )
  );
  // Cut the building/suite/floor from the streetLine, but not if it becomes empty
  const cleanStreet = usingStreetLine ?
    (cutFromAddressLine(streetLine, buildingSuiteFloor) || streetLine)
    : addressLine2;
  return {
    customerdescription: '', /* Not part of addressify list */
    address1: cleanStreet,
    address2: buildingSuiteFloor,
    city: suburb,
    state,
    postcode
  };
};

// const getAddressDetails = (dispatch, updateAddressDetails, query: string) => {
//   axios.get(addressDetailsUrl(query)).then(({ data }: { data: Res }) => {
//     dispatch(updateAddressDetails, translateAddress(data));
//   })
//     .catch((error) => {
//       errorToast();
//     });
// };

export default translateAddress;
