export const doAdobe = (payload = {}, linkName = '') => {
  // only fire if there is one or more events
  if (typeof s === 'undefined') { return; }
  // we either want to record events or prop68 which is issues
  if (!payload.events && typeof payload.prop68 === 'undefined') { return; }
  const linkTrackVarsArr = [];
  if (payload.events) {
    s.events = payload.events;
    // turn "ev1=1,ev2,ev3" into 'ev1,ev2,ev3' for linkTrackEvents
    s.linkTrackEvents = payload.events.split(',').reduce((acc, cur) => {
      return [...acc, cur.split('=')[0]];
    }, []).join(',');
    linkTrackVarsArr.push('events');
  }
  Object.keys(payload).forEach((el) => {
    if (el !== 'events') {
      s[el] = payload[el];
      linkTrackVarsArr.push(el);
    }
  });
  s.linkTrackVars = linkTrackVarsArr.join(',');
  s.tl(true, 'o', linkName);
};

// expects an object like the below as an argument.
// along with link name
// {
//   "eVar58": "contract",
//   "prop58": "contract",
//   "prop27": "superuser",
//   "eVar21": "1139231",
//   "prop26": "AAA114",
//   "eVar16": "NET50645228",
//   "eVar27": "superuser",
//   "prop1": "au_dotcom",
//   "eVar22": "XXX",
//   "prop22": "XXX",
//   "prop21": "1139231",
//   "eVar25": "Commercial Accounts",
//   "events": "",
//   "eVar26": "AAA114",
//   "prop25": "Commercial Accounts",
//   "eVar1": "au_dotcom"
// }

export const doAdobeEvents = (events, vars = {}, linkName = '') => {
  // only fire if there is one or more events
  if (typeof s === 'undefined') { return; }
  const linkTrackVarsArr = [];

  Object.keys(vars).forEach((el) => {
    s[el] = vars[el];
    linkTrackVarsArr.push(el);
  });

  const linkTrackEventsArr = [];
  const eventsArr = [];
  Object.keys(events).forEach((el) => {
    if (events[el]) {
      eventsArr.push(`${el}=${events[el]}`);
    } else {
      eventsArr.push(el);
    }
    const [ev, sv] = el.split(':');
    if (ev) {
      linkTrackEventsArr.push(ev);
    } else {
      linkTrackEventsArr.push(el);
    }
  });

  s.events = eventsArr.join(',');
  s.linkTrackEvents = linkTrackEventsArr.join(',');
  linkTrackVarsArr.push('events');

  s.linkTrackVars = linkTrackVarsArr.join(',');
  s.tl(true, 'o', linkName);
};

// Called when we want to do s.t instead of s.tl.
// This fires a pageview instead of a page event.
// We recycle all the evars and props from the original omniture.pm,
// except those related to pagename, or extras passed in
// e.g. doAdobePageView(`quote:${st.status}`, {}, { prop6: '/main-your-quote' });
export const doAdobePageView = (pageName, extras = {}) => {
  s.t({
    pageName,
    eVar3: pageName,
    prop2: pageName,
    hier1: pageName,
    ...extras,
  });
};
