<template>
  <select
    class="nx-select"
    :name="name"
    :disabled="disabled"
    @change="this.handleChange"
    v-model="select_default"
  >
    <option
      v-for="(option, index) in options"
      v-bind:key="index"
      v-bind:value="option.value"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'NxSelect',
  props: {
    options: Array,
    name: String,
    handleChange: Function,
    value: String,
    disabled: Boolean,
  },
  computed: {
    select_default: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
<!-- For Development purpose only, comment it for prod build -->
<!--
<style lang="scss">
@import '../../../../build/sass/components/common/_nx_select.scss';
</style>
-->
