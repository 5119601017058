export const hasEtd = (editable) =>
  (window.nxDatalayer.global.profiles.EtdUsers || window.nxDatalayer.global.streams.control_etd)
    && editable;

export const buildEtdUrl = (items, postcode) => {
  // we should return an array of urls, each with 50 items or less
  const urlArr = [];
  const pcodes = items.map((item) => item.prodcode);
  const etdBaseUrl = `${window.nxDatalayer.global.etd_endpoint}au/${postcode}`;
  for (let i = 0; i < pcodes.length; i += 50) {
    const batch = pcodes.slice(i, i + 50);
    urlArr.push(batch.reduce((acc, val) => `${acc}&material=${val}`, `${etdBaseUrl}?`));
  }
  return urlArr;
};
