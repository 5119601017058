import Vue from 'vue';
import { InputType } from './types';
import View from './view';

const InputGroup = Vue.extend({
  name: 'inputGroup',
  data() {
    return {
      dirty: false,
    };
  },
  methods: {
    handleAndMark(args) {
      this.dirty = true;
      this.handleChange(args);
    }
  },
  computed: {
    validationStatus() {
      if (this.icontype) {
        return this.icontype;
      }
      if (this.error) {
        return 1;
      }
      if (!this.preventTick &&
        (this.dirty || (this.tickValid && this.value !== ''))) {
        return 2;
      }
      return 0;
    },
  },
  props: {
    name: {
      type: String
    },
    value: {
      type: String
    },
    label: {
      type: String
    },
    id: {
      type: String
    },
    type: {
      type: Number,
      default: InputType.Text
    },
    fieldtype: {
      type: String
    },
    handleChange: {
      type: Function
    },
    icontype: {
      type: Number
    },
    handleBlur: {
      type: Function,
    },
    validationResult: {
      type: String
    },
    error: {
      type: String,
      default: ''
    },
    tooltipHelpMsg: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputClassName: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array
    },
    maxLength: {
      type: String
    },
    tickValid: {
      type: Boolean,
      default: false,
    },
    showDollar: {
      type: Boolean,
      default: false,
    },
    preventTick: { // if this is true, tick will never show
      type: Boolean,
      default: false,
    },
  },
  render() {
    const {
      id,
      label,
      type,
      fieldtype,
      validationStatus,
      name,
      error,
      value,
      tooltipHelpMsg,
      placeholder,
      inputClassName,
      className,
      disabled,
      options,
      maxLength,
      handleAndMark,
      showDollar,
      handleBlur,
    } = this;
    return (
      // @ts-ignore
      <View
        // @ts-ignore
        id={id}
        label={label}
        type={type}
        fieldtype={fieldtype}
        value={value}
        handleChange={handleAndMark}
        error={error}
        tooltipHelpMsg={tooltipHelpMsg}
        placeholder={placeholder}
        handleBlur={handleBlur}
        // @ts-ignore
        inputClassName={inputClassName}
        // @ts-ignore
        className={className}
        disabled={disabled}
        // @ts-ignore
        options={options}
        maxLength={maxLength}
        validationStatus={validationStatus}
        showDollar={showDollar}
      />
    );
  }
});

export default InputGroup;
