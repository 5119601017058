const getCookie = function getCookie(cname) {
  const re = new RegExp(`${cname}=([^;]+)`);
  const value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : null;
};

const setCookie = function setCookie(cname, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `; expires=${date.toGMTString()}`;
  document.cookie = `${cname}=${value}${expires}; path=/`;
};

// prevent toasts repeating too soon
const throttle = {
  longTimeout: 60 * 1000,
  expiry: {},
  pass: function pass(title, text, timeout) {
    const input = [title, text].join(' ');
    const time = Date.now();
    let status;
    if (this.expiry[input] && time < this.expiry[input]) {
      status = false;
    } else {
      // timeout = -1 can be used - where we'll still timeout, only wait a while
      this.expiry[input] = time + (timeout > 0 ? timeout : this.longTimeout);
      status = true;
    }
    return status;
  },
};

export const makeToast = (args) => {
  const title = args.title || '';
  const text = args.text || '';
  const type = args.type || 'success';
  toastr.options = {
    timeOut: args.duration || 5000,
  };
  if (!throttle.pass(title, text, toastr.options.timeOut)) {
    return;
  }
  toastr[type](text, title);
};

export const isMocking = window.isMocking === true;

export const getClassName = (condition, className) => (condition ? className : '');

export const cStr = (...args) => {
  let r = '';
  for (let i = 0; i < args.length; i += 2) {
    if (args[i]) {
      r = `${r} ${args[i + 1]}`;
    }
  }
  return r;
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export { getCookie, setCookie }; // eslint-disable-line import/prefer-default-export

export const considerGst = (price) => {
  if (!price) return '';
  if (window.nxDatalayer.global.show_gst) {
    return price.total.string;
  }
  return price.base.string;
};

export const isPORefMandatory = (user, costCentre) =>
  user.bypass_po_validation
    ? false
    : (costCentre.referencemandatory || user.flipreference);

export const orderURLs = (order = window.nxDatalayer.env.cart_endpoint) => {
  const address = `${order}/da`;
  const creditcard = `${order}/creditcard`;
  const skip = `${order}/skip`;
  const revert = `${order}/revert`;
  const coupon = `${order}/coupon`;
  const item = `${order}/item`;
  const approvers = `${order}/a`;

  return { order, address, creditcard, skip, revert, coupon, item, approvers };
};

export const validEmail = function validEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export function extension(filename) {
  const n = filename.lastIndexOf('.');
  const ext = filename.substring(n + 1);
  return ext
    ? ext.toUpperCase()
    : '';
}
