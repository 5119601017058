import { isEmpty } from '@/helpers/util';
import { getClassName } from '../../../../helpers/helpers';

const Label = {
  functional: true,
  render(createElement, { props: { label, id = '', hasError = false } }) {
    const className = `checkout__label ${getClassName(hasError, 'checkout__label--error')}`;
    return isEmpty(id) ? (
      <div class={className}>{label}</div>
    ) : (
      <label for={id} class={className}>
        {label}
      </label>
    );
  }
};

export default Label;
