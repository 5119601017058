import Vue from 'vue';
import { isEmpty } from '@/helpers/util';
import { objectEveryValueIsEmpty, scheduleDefault } from '@/components/Checkout/helpers';
/* These are just remaps/restructures of field names, and could be
 * removed. */
import { getItems } from '@/components/Checkout/apis/helpers/getItems';
import { getCoupons } from '@/components/Checkout/apis/helpers/getCoupons';
import { updateOrderDetails } from '@/components/OrderDetails/OrderDetailsStore';
import { doAdobeEvents } from '@/helpers/adobe';
import postOrderApprovers from './apis/postApprovers';
import { valid, validate, addressValidationRules, billingValidationRules } from '../../helpers/validators';
import { eighteenToday } from './Payment/Alcohol/helpers';
import { orderURLs } from '../../helpers/helpers';

const state = {
  apps: [],
  apps_selection: { },
  contactname: '',
  contactemail: '',
  contactphone: '',
  contactmobilephone: '',
  email_bcc: '',
  quote_no: '',
  ordernote: '',
  permissions: {},
  cc: {},
  whencreated: '',
  alternatives: {},
  cost: {},
  approval_required: false,
  opi: {},
  rejected: false,
  has_custom_item: false,
  custom_item_acknowledged: false,
  has_conf_prod: false,
  orderid: 'NET#',
  customerreference: '',
  price: '',
  alcohol_acknowledged: false,
  issues: [],
  total_seen_gst: false,
  total_seen: 0,
  cp_acknowledged: false,
  coupons: [],
  shopping_lists: {},
  items: [],
  u: {},
  billing: {
    sameas: true,
  },
  customerdescription: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postcode: '',
  country: '',
  addresses: [],
  selectedAddressIndex: -1,
  authoritytoleave: undefined,
  forwarddate: null,
  schedule: null,
  deliverynote: '',
  personplacingname: '',
  personplacingdob: '',
  deliveryrecipientname: '',
  orderid_hmac: '',
  url: '',
  consolidate_edd: '',
  is_consolidate: null,
  checkout_snippets: {},
  env_message: {},
  // if we've already recorded in adobe that the postcode has changed
  sentPostcodeChangedEvent: false,
  // this is the step which is currently in focus
  focusStep: 'contact',

  /* This is state used to manage error display and checking */
  check_step: 0,

  /* field_updated determines if there has been a field modified from
   * previous loaded state. */
  field_updated: {},

  initialised: false,
  oneTimeAddressChosen: false,
  simerror_code: 'none',
  simerror_prodcode: null,
};

const form_part = [
  ['contact', 'approval', 'reference'],
  ['address', 'atl', 'deliverynote', 'consolidate', 'billing', 'schedule'],
  ['payment', 'terms', 'alcohol']
];

const part_section = { };
for (let i = 0; i < form_part.length; i += 1) {
  form_part[i].forEach((x) => {
    part_section[x] = i;
  });
}

/* Getters */
export const rest = '@checkout/order/getters/rest';
export const orderID = '@checkout/order/getters/orderid';
export const contactForm = '@checkout/order/getters/contactform';
export const contactRest = '@checkout/order/getters/contactrest';
export const seenRest = '@checkout/order/getters/seenRest';
export const postcode = '@checkout/order/getters/postcode';
export const stateCode = '@checkout/order/getters/stateCode';

// URLs for the various actions
export const orderURL = '@checkout/order/getters/orderURL';

// Order Information for cost and reference

export const referenceRest = '@checkout/order/getters/referencerest';
export const referenceData = '@checkout/order/getters/referencedata';
export const referenceMandatory = '@checkout/order/getters/referencemandatory';
export const orderCost = '@checkout/order/getters/orderCost';
export const ccDisplay = '@checkout/order/getters/ccDisplay';

export const termsData = '@checkout/order/getters/termsdata';
export const termsRest = '@checkout/order/getters/termsrest';
export const alcoholData = '@checkout/order/getters/alcoholdata';
export const alcoholRest = '@checkout/order/getters/alcoholrest';
export const checkoutSnippets = '@checkout/order/getters/checkoutSnippets';

export const approvalRequired = '@checkout/order/getters/approvalrequired';
export const approvalRules = '@checkout/order/getters/approvalrules';
export const approvalSelections = '@checkout/order/getters/approvalselections';
export const approvalModified = '@checkout/order/getters/approvalmodified';

export const checkStep = '@checkout/order/getters/checkstep';
export const completeStep = '@checkout/order/getters/completestep';
export const focusStep = '@checkout/order/getters/focusStep';

/* Error checking - All errors are organised into groups, then
   sections.  A group is valid if there are no errors in the group. A
   section is valid if all groups inside are valid. A group which is
   omitted or not required should just return { valid: true }.

   A section is considered complete if it is valid. Errors in a
   section can be ignored for display if completeStep is not yet
   passed. */

const errorPrefix = '@checkout/order/getters/error';
export const orderError = '@checkout/order/getters/error/order';
export const orderValid = '@checkout/order/getters/ordervalid';
export const referenceError = '@checkout/order/getters/error/reference';
export const contactError = '@checkout/order/getters/error/contact';
export const approvalError = '@checkout/order/getters/error/approval';
export const addressError = '@checkout/order/getters/error/address';
export const paymentError = '@checkout/order/getters/error/payment';
export const atlError = '@checkout/order/getters/error/atl';
export const consolidateError = '@checkout/order/getters/error/consolidate';
export const deliverynoteError = '@checkout/order/getters/error/deliverynote';

/* The scrollpart is the topmost sub-part in error state. */
export const scrollPart = '@checkout/order/getters/scrollpart';

export const billingError = '@checkout/order/getters/error/billing';
export const scheduleError = '@checkout/order/getters/error/schedule';
export const termsError = '@checkout/order/getters/error/terms';
export const alcoholError = '@checkout/order/getters/error/alcohol';

export const fieldIssue = '@checkout/order/getters/fieldissue';

// address
export const useATL = '@checkout/order/getters/useATL';
export const shippingRest = '@checkout/order/getters/shippingRest';
export const shippingData = '@checkout/order/getters/shippingData';
export const consolidatable = '@checkout/order/getters/consolidatable';
export const addresses = '@checkout/order/getters/addresses';
export const selectedAddressIndex = '@checkout/order/getters/selectedAddressIndex';

// scheduling
export const scheduleRest = '@checkout/order/getters/scheduleRest';
export const scheduleData = '@checkout/order/getters/scheduleData';
export const forwardOrder = '@checkout/order/getters/forwardOrder';
export const recurringOrder = '@checkout/order/getters/recurringOrder';
export const nowOrder = '@checkout/order/getters/nowOrder';

// items
export const items = '@checkout/order/getters/items';
export const hasSUPItem = '@checkout/order/getters/hasSUPItem';
export const coupons = '@checkout/order/getters/coupons';

// user data
export const user = '@checkout/order/getters/user';
export const permissions = '@checkout/order/getters/permissions';
export const adobe = '@checkout/order/getters/adobe';

// Used for authenticated links (order confirmation)
export const checksum = '@checkout/order/getters/checksum';

// User for OneTimeAddress Chosen
export const oneTimeAddressChosen = '@checkout/order/getters/oneTimeAddressChosen';

// Simulate error code
export const simErrorCode = '@checkout/order/getters/simErrorCode';
export const simErrorProdCode = '@checkout/order/getters/simErrorProdCode';

const getters = {
  [rest](st) {
    const order = { ...st };
    return order;
  },
  [orderURL](st) {
    return orderURLs(st.url);
  },
  [adobe](st) {
    return st.adobe;
  },
  [items](st) {
    return getItems(st.items);
  },
  [hasSUPItem](st) {
    if (st.simerror_code === 'single_use_plastic_error') {
      return true;
    }

    return st.items.some((item) => item.single_use_plastic);
  },
  [coupons](st) {
    const coupon_items = st.coupons.filter((x) => { return x.item; });

    return getCoupons(coupon_items);
  },
  [checksum](st) {
    return st.orderid_hmac;
  },
  [oneTimeAddressChosen](st) {
    return st.oneTimeAddressChosen;
  },
  [simErrorCode](st) {
    return st.simerror_code;
  },
  [simErrorProdCode](st) {
    return st.simerror_prodcode;
  },
  [approvalRequired](st) {
    return st.approval_required;
  },
  [checkoutSnippets](st) {
    return st.checkout_snippets;
  },
  [scrollPart](st, getters) {
    const cs = getters[completeStep];
    const as = getters[checkStep];

    /* We have not yet moved to the incomplete section */
    if ((as <= cs) && form_part[as]) {
      return form_part[as][0];
    }

    const errors = getters[orderError];
    if (cs > 2) {
      return 'payment';
    }

    const parts = form_part[cs];
    const err_parts = errors.sections[cs];
    for (let i = 0; i < parts.length; i += 1) {
      const p = parts[i];
      if (!err_parts[p].valid) {
        return p;
      }
    }
    return null;
  },
  [completeStep](st, getters) {
    const errors = getters[orderError].sections;
    let complete = 0;

    if (!st.initialised) {
      return -1;
    }

    for (complete = 0; complete < errors.length; complete += 1) {
      if (!errors[complete].valid) {
        break;
      }
    }
    return complete;
  },
  [focusStep](st) {
    return st.focusStep;
  },
  [fieldIssue](st) {
    const r = { };
    st.issues.forEach((i) => {
      if (i.field) {
        r[i.field] = i;
      }
    });
    return r;
  },
  [checkStep](st, getters) {
    return st.check_step;
  },
  [orderValid](st, getters) {
    const oe = getters[orderError];
    const { sections } = oe;
    let valid = true;
    for (let i = 0; i < sections.length; i += 1) {
      valid = valid && sections[i].valid;
    }
    return valid;
  },
  [orderError](st, getters) {
    const sections = [];
    const display = {};
    const { check_step } = st;

    const r = {
      sections,
      display,
    };

    for (let i = 0; i < form_part.length; i += 1) {
      const errors = { };
      const updated = st.field_updated;
      let sec_valid = true;
      for (let j = 0; j < form_part[i].length; j += 1) {
        const sec_name = form_part[i][j];
        const ps = part_section[sec_name];
        const display_errors = {};
        errors[sec_name] = getters[`${errorPrefix}/${sec_name}`];
        sec_valid = sec_valid && errors[sec_name].valid;

        Object.keys(errors[sec_name]).forEach((e) => {
          if (updated[e] || ps < st.check_step) {
            display_errors[e] = errors[sec_name][e];
          }
        });
        display[sec_name] = display_errors;
      }
      errors.valid = sec_valid;
      sections.push(errors);
    }
    return {
      display,
      sections
    };
  },
  [contactError](st, getters) {
    const fields = {
      contactname: [
        valid.notEmpty,
        valid.minLength(2),
        valid.maxLength(80),
      ],
      contactemail: [
        valid.notEmpty,
        valid.minLength(5),
        valid.maxLength(255),
        valid.space
      ],
      contactphone: [
        valid.notEmpty,
        valid.minLength(5),
        valid.maxLength(20)
      ],
      contactmobilephone: [valid.maxLength(20)],
      email_bcc: [valid.maxLength(255)],
      quote_no: [valid.maxLength(50)],
      ordernote: [valid.maxLength(255)],
    };
    const labels = {
      contactname: 'Name',
      contactemail: 'Email',
      contactphone: 'Telephone',
      contactmobilephone: 'Mobile',
      email_bcc: 'CC Emails',
      quote_no: 'Quote Number',
      ordernote: 'Notes'
    };
    return valid.fields(
      st, fields, labels, st.field_updated, getters[fieldIssue]
    );
  },
  [referenceMandatory](st) {
    if (st.u.bypass_po_validation) {
      return false;
    }
    return st.cc.referencemandatory || st.u.flipreference;
  },
  [referenceError](st, getters) {
    const data = getters[referenceData];
    if (getters[referenceMandatory]) {
      return valid.fields(
        st,
        { customerreference: [valid.notEmpty] },
        { customerreference: data.referencename },
        st.field_updated,
        getters[fieldIssue]
      );
    }
    return { valid: true };
  },
  [approvalError](st, getters) {
    const ars = getters[approvalRules];
    const errors = { };
    let valid = true;
    Object.values(ars).forEach((el) => {
      el.approverGroups.forEach((ag) => {
        if (ag.error) {
          errors[ag.id] = ag.error;
          if (ag.error) {
            valid = false;
          }
        }
      });
    });
    errors.valid = valid;
    return errors;
  },
  [addressError](st, getters) {
    return valid.fields(
      st,
      addressValidationRules,
      {},
      st.field_updated,
      getters[fieldIssue],
    );
  },
  [atlError](st, getters) {
    if (getters[useATL]) {
      return valid.fields(
        st,
        { authoritytoleave: [valid.boolean] },
        { authoritytoleave: 'Authority to Leave' },
        st.field_updated,
        getters[fieldIssue]
      );
    }
    return { valid: true };
  },
  [consolidateError](st, getters) {
    if (getters[consolidatable]) {
      return valid.fields(
        st,
        { is_consolidate: [valid.notEmpty] },
        { is_consolidate: 'Consolidate order' },
        st.field_updated,
        getters[fieldIssue]
      );
    }
    return { valid: true };
  },
  [deliverynoteError](st, getters) {
    return valid.fields(
      st,
      { deliverynote: [valid.maxLength(90)] },
      {},
      st.field_updated,
      getters[fieldIssue]
    );
  },
  [billingError](st, getters) {
    if (getters[user].is_retail && !st.billing.sameas) {
      return valid.fields(
        {
          billing_address1: st.billing.address1,
          billing_address2: st.billing.address2,
          billing_city: st.billing.city,
          billing_postcode: st.billing.postcode,
          billing_state: st.billing.state,
        },
        billingValidationRules,
        {},
        st.field_updated,
        getters[fieldIssue],
      );
    }
    return { valid: true };
  },
  [scheduleError](st, getters) {
    const { schedule } = getters[scheduleRest];
    if (schedule.recurrencetype === 'W'
      && schedule.weekly_monday === false
      && schedule.weekly_tuesday === false
      && schedule.weekly_wednesday === false
      && schedule.weekly_thursday === false
      && schedule.weekly_friday === false
    ) {
      return {
        valid: false,
        weekly_day: 'Select one',
      };
    }
    return { valid: true };
  },
  [paymentError](st) {
    return { valid: true };
  },
  [orderID](st) {
    return st.orderid;
  },
  [contactForm](st) {
    const contact = {
      contactname: st.contactname,
      contactemail: st.contactemail,
      contactphone: st.contactphone,
      contactmobilephone: st.contactmobilephone,
      email_bcc: st.email_bcc,
      quote_no: st.quote_no,
      ordernote: st.ordernote
    };
    return contact;
  },
  [postcode](st) {
    return st.postcode;
  },
  [stateCode](st) {
    return st.state;
  },
  [seenRest](st) {
    const hasGst = st.u.inc_gst;
    let total_seen = 0;
    const total_seen_gst = hasGst;

    if (st.cost?.total) {
      total_seen = hasGst
        ? st.cost.total.total.mills
        : st.cost.total.base.mills;
    }

    return { total_seen, total_seen_gst };
  },
  [contactRest](st) {
    const picked = {
      contactname: st.contactname,
      contactemail: st.contactemail,
      contactphone: st.contactphone,
      contactmobilephone: st.contactmobilephone,
      email_bcc: st.email_bcc,
      quote_no: st.quote_no,
      ordernote: st.ordernote
    };
    return picked;
  },
  [alcoholData](st) {
    const {
      alcohol_acknowledged,
      personplacingname,
      personplacingdob,
      deliveryrecipientname,
      has_liquor
    } = st;
    return {
      alcohol_acknowledged,
      personplacingname,
      personplacingdob: personplacingdob || eighteenToday().toISOString().substr(0, 10),
      deliveryrecipientname,
      has_liquor
    };
  },
  [alcoholRest](st, getters) {
    const {
      alcohol_acknowledged,
      personplacingname,
      personplacingdob,
      deliveryrecipientname,
      has_liquor,
    } = getters[alcoholData];
    if (has_liquor && alcohol_acknowledged) {
      return {
        personplacingname,
        personplacingdob,
        deliveryrecipientname,
      };
    }
    return {
      personplacingname: '',
      personplacingdob: '',
      deliveryrecipientname: ''
    };
  },
  [alcoholError](st) {
    const fields = { };
    if (st.has_liquor) {
      fields.alcohol_acknowledged = [valid.selected];
      fields.personplacingname = [valid.notEmpty, valid.maxLength(80)];
      fields.deliveryrecipientname = [valid.notEmpty, valid.maxLength(80)];
      fields.personplacingdob = [valid.notEmpty];
    }
    return valid.fields(st, fields);
  },
  [termsData](st) {
    const {
      has_custom_item,
      has_conf_prod,
      cp_acknowledged,
      custom_item_acknowledged
    } = st;
    return {
      has_custom_item,
      has_conf_prod,
      cp_acknowledged,
      custom_item_acknowledged
    };
  },
  [termsRest](st) {
    const {
      cp_acknowledged,
      custom_item_acknowledged
    } = st;
    return {
      cp_acknowledged,
      custom_item_acknowledged
    };
  },
  [termsError](st) {
    const fields = { };
    if (st.has_custom_item) {
      fields.custom_item_acknowledged = [valid.selected];
    }
    if (st.has_conf_prod) {
      fields.cp_acknowledged = [valid.selected];
    }
    return valid.fields(st, fields);
  },
  [referenceData](st) {
    const { customerreference, cc: { referencename, defaultreference } } = st;
    return {
      customerreference,
      referencename: (referencename || 'Your Order Reference'),
      referenceplaceholder: (referencename || 'Order Reference'),
      defaultreference
    };
  },
  [referenceRest](st) {
    const picked = {
      customerreference: st.customerreference
    };
    return picked;
  },
  [approvalRules](st) {
    const { apps } = st;
    const am = [];
    apps.forEach((el) => {
      const vals = [...el.values];
      const uids = [...el.uids];
      const { appid, label, current } = el;
      if (vals.length > 1 && !current) {
        vals.unshift('Please select');
        uids.unshift('');
      }
      am.push({ values: vals, uids, appid, label });
    });

    const grouped = {};
    am.forEach(({ appid, label, uids, values }) => {
      const id = appid.split('-')[0];
      const current = st.apps_selection[appid];
      let error = '';
      const app_sec = part_section.approval;

      // Only show the error if the check_step is passed.
      if (app_sec < st.check_step && !current) {
        error = 'Please select';
      }

      if (isEmpty(grouped[id])) {
        grouped[id] = { name: label, id, approverGroups: [] };
      }
      grouped[id].approverGroups.push({
        id: appid, values, uids, current, error
      });
    });

    return grouped;
  },
  [approvalModified](st) {
    let modified = false;
    st.apps.forEach(({ appid, uids, current }) => {
      if (st.apps_selection[appid] !== current) {
        modified = true;
      }
    });
    return modified;
  },
  [approvalSelections](st) {
    const apps = [];
    st.apps.forEach(({ appid, uids }) => {
      if (st.apps_selection[appid]) {
        apps.push({ appid, uid: st.apps_selection[appid] });
      }
    });
    return apps;
  },
  [useATL](st) {
    return st.u.is_home_delivery || st.u.is_retail;
  },
  [addresses]: ({ addresses }) => {
    return addresses.map((el, idx) => {
      el.actualIndex = idx;
      return el;
    });
  },
  [selectedAddressIndex]: (st, getters) => {
    const actual = getters[shippingData];
    const sel = st.addresses[st.selectedAddressIndex];
    if (typeof sel !== 'undefined' &&
      actual.customerdescription === sel.description &&
      actual.address2 === sel.address2 &&
      actual.address1 === sel.address1 &&
      actual.postcode === sel.postcode &&
      actual.state === sel.state &&
      actual.city === sel.city
    ) {
      return st.selectedAddressIndex;
    }
    return -1;
  },
  [shippingRest](st, getters) {
    const rest = {};
    if (getters[useATL]) {
      rest.authoritytoleave = st.authoritytoleave;
    }
    if (getters[consolidatable]) {
      rest.is_consolidate = st.is_consolidate;
    }

    /* If the customer description is empty, then we are not using
     * the address book and we should normalise the address fields. */

    if (!st.customerdescription) {
      /* Normalise spaces for address fields */
      rest.address1 = st.address1.replace(/\s+/g, ' ');
      rest.address2 = st.address2.replace(/\s+/g, ' ');
      rest.city = st.city.replace(/\s+/g, ' ');
      rest.state = st.state.replace(/\s+/g, ' ');
      rest.postcode = st.postcode.replace(/\s+/g, ' ');

      /* Remove leading & trailing spaces for address fields */
      rest.address1 = rest.address1.trim();
      rest.address2 = rest.address2.trim();
      rest.city = rest.city.trim();
      rest.state = rest.state.trim();
      rest.postcode = rest.postcode.trim();
    } else {
      /* match exact */
      rest.customerdescription = st.customerdescription;
      rest.address1 = st.address1;
      rest.address2 = st.address2;
      rest.city = st.city;
      rest.state = st.state;
      rest.postcode = st.postcode;
    }
    rest.country = 'AU'; // always Australia until the business changes
    rest.deliverynote = st.deliverynote;
    if (getters[user].is_retail) {
      if (st.billing.sameas) {
        rest.billing = { sameas: true };
      } else if (getters[billingError].valid) {
        rest.billing = {
          address1: st.billing.address1,
          address2: st.billing.address2,
          city: st.billing.city,
          state: st.billing.state,
          postcode: st.billing.postcode,
          country: 'AU',
          sameas: false,
        };
      }
    }
    return rest;
  },
  [shippingData](st, getters) {
    const rest = {};
    if (getters[useATL]) {
      rest.authoritytoleave = st.authoritytoleave;
    }
    rest.consolidate_edd = st.consolidate_edd;
    rest.is_consolidate = st.is_consolidate;
    rest.customerdescription = st.customerdescription;
    rest.address1 = st.address1;
    rest.address2 = st.address2;
    rest.city = st.city;
    rest.state = st.state;
    rest.postcode = st.postcode;
    rest.country = st.country;
    rest.deliverynote = st.deliverynote;
    if (st.billing.sameas) {
      rest.billing = { sameas: true };
    } else if (getters[billingError].valid) {
      rest.billing = {
        address1: st.billing.address1,
        address2: st.billing.address2,
        city: st.billing.city,
        state: st.billing.state,
        postcode: st.billing.postcode,
        sameas: false,
      };
    } else {
      rest.billing = {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postcode: '',
        sameas: false,
      };
    }
    return rest;
  },
  [orderCost](st) {
    return st.cost;
  },
  [ccDisplay](st) {
    return {
      label: st.cc.display[0],
      value: st.cc.display[1]
    };
  },
  [scheduleRest](st, getters) {
    const ret = { schedule: {} };
    const sd = getters[scheduleData];
    ret.forwarddate = sd.forwarddate;
    if (st.schedule) {
      ret.schedule.recurrencetype = sd.schedule.recurrencetype;
      ret.schedule.startdate = sd.schedule.startdate;
      ret.schedule.enddate = sd.schedule.enddate;
      ret.schedule.weekly_frequence = sd.schedule.weekly_frequence;
      ret.schedule.weekly_friday = sd.schedule.weekly_friday;
      ret.schedule.weekly_monday = sd.schedule.weekly_monday;
      ret.schedule.weekly_saturday = sd.schedule.weekly_saturday;
      ret.schedule.weekly_sunday = sd.schedule.weekly_sunday;
      ret.schedule.weekly_thursday = sd.schedule.weekly_thursday;
      ret.schedule.weekly_tuesday = sd.schedule.weekly_tuesday;
      ret.schedule.weekly_wednesday = sd.schedule.weekly_wednesday;
      ret.schedule.monthly_type = sd.schedule.monthly_type;
      ret.schedule.monthly_date = sd.schedule.monthly_date;
      ret.schedule.monthly_frequence_of_day = sd.schedule.monthly_frequence_of_day;
      ret.schedule.monthly_nthday_of_week = sd.schedule.monthly_nthday_of_week;
      ret.schedule.monthly_day_of_week = sd.schedule.monthly_day_of_week;
      ret.schedule.monthly_frequence_of_week = sd.schedule.monthly_frequence_of_week;
      ret.schedule.total_occurrences = sd.schedule.total_occurrences;
      ret.schedule.enddate_type = sd.schedule.enddate_type;
      ret.schedule.send_additional_order = sd.schedule.send_additional_order;

      ret.schedule.order_multiples = 1;
    }
    return ret;
  },
  [scheduleData](st, getters) {
    const ret = { ...scheduleDefault };
    ret.forwarddate = st.forwarddate;
    if (st.schedule) {
      ret.schedule = { ...ret.schedule, ...st.schedule };
    }
    return ret;
  },
  [forwardOrder](st, getters) {
    const sd = getters[scheduleData];
    return !!sd.forwarddate && sd.schedule.recurrencetype === 'O';
  },
  [recurringOrder](st, getters) {
    const sd = getters[scheduleData];
    return (sd.schedule.recurrencetype !== 'O');
  },
  [nowOrder](st, getters) {
    return !getters[forwardOrder] && !getters[recurringOrder];
  },
  [consolidatable](st, getters) {
    return st.consolidate_edd && getters[nowOrder] &&
           !getters[approvalRequired] && !!window.nxDatalayer.global.streams.dcon;
  },
  [user](st) {
    return st.u;
  },
  [permissions](st) {
    return st.permissions;
  },
};

/* Actions */

export const setFocusStep = '@checkout/order/actions/setFocusStep';
export const update = '@checkout/order/actions/update';
export const resetUpdated = '@checkout/order/actions/resetUpdated';
export const resetAddressUpdated = '@checkout/order/actions/resetAddressUpdated';
export const setDefaultReference = '@checkout/order/actions/setDefaultReference';
export const orderInitialised = '@checkout/order/actions/orderInitialised';
export const updateContact = '@checkout/order/actions/updateContact';
export const updateField = '@checkout/order/actions/updateField';
export const updateBilling = '@checkout/order/actions/updateBilling';
export const selectApprover = '@checkout/order/actions/selectapprover';
export const sendApprovers = '@checkout/order/actions/sendapprovers';
export const updateSchedule = '@checkout/order/actions/updateSchedule';
export const updateAddresses = '@checkout/order/actions/updateAddresses';
export const updateSelectedAddressIndex = '@checkout/order/actions/selectedAddressIndex';
export const updateSelectedAddressBySapId = '@checkout/order/actions/updateSelectedAddressBySapId';
export const setOneTimeAddressChosen = '@checkout/order/actions/oneTimeAddressChosen';

export const updateCheckStep = '@checkout/order/actions/updateCheckStep';
export const resetCheckStep = '@checkout/order/actions/resetCheckStep';

export const adobePropTL = '@checkout/order/actions/adobePropTL';

const field_section = {
  reference: ['customerreference'],
  contact: [
    'contactname', 'contactphone', 'contactmobilephone',
    'contactemail', 'email_bcc', 'quote_no', 'ordernote'
  ],
  approval: ['approval'], // special case
  address: ['address1', 'address2', 'city', 'state', 'postcode'],
  atl: ['authoritytoleave'],
  consolidate: ['is_consolidate'],
  billing: [
    'billing_address1',
    'billing_address2',
    'billing_city',
    'billing_state',
    'billing_postcode',
    'billing_sameas'
  ],
  schedule: [
    'recurrencetype',
    'weekly_friday',
    'weekly_monday',
    'weekly_saturday',
    'weekly_sunday',
    'weekly_thursday',
    'weekly_tuesday',
    'weekly_wednesday',
    'startdate',
    'enddate',
    'enddate_type',
    'total_occurrences',
    'monthly_type',
    'monthly_date',
    'monthly_frequence_of_day',
    'monthly_nthday_of_week',
    'monthly_day_of_week',
    'monthly_frequence_of_week',
    'forwarddate',
  ],
  alcohol: [
    'alcohol_acknowledged', 'personplacingname',
    'deliveryrecipientname', 'personplacingdob'
  ],
  terms: ['cp_acknowledged', 'custom_item_acknowledged'],
  payment: ['creditcard']
};

const fmap = { };
Object.keys(field_section).forEach((k) => {
  field_section[k].forEach((f) => {
    fmap[f] = k;
  });
});

const actions = {
  [update]({ commit, dispatch, getters }, payload) {
    commit(update, payload);
    if (payload.orderid) {
      commit(updateOrderDetails, payload);
    }
    if (getters[approvalModified]) {
      dispatch(sendApprovers);
    }
  },
  [orderInitialised]({ commit }) {
    commit(orderInitialised);
  },
  [resetUpdated]({ commit }) {
    commit(resetUpdated);
  },
  [resetAddressUpdated]({ commit }) {
    commit(resetAddressUpdated);
  },
  [resetCheckStep]({ commit }) {
    commit(resetCheckStep);
  },
  [setFocusStep]({ commit }, value) {
    commit(setFocusStep, value);
  },
  [setDefaultReference]({ commit }) {
    commit(setDefaultReference);
  },
  [sendApprovers]({ commit, dispatch, getters }) {
    const { approvers } = getters[orderURL];
    postOrderApprovers(approvers, { apps: getters[approvalSelections] }).then((res) => {
      if (res && res.data) {
        const { apps } = res.data;
        commit(update, { apps });
      }
    });
  },
  [adobePropTL]({ getters }, { pass, props, linkName = '' }) {
    if (typeof s === 'undefined') { return; }
    const vars = [];
    const adobe_data = getters[adobe];

    pass.forEach((p) => {
      s[p] = adobe_data[p];
      vars.push(p);
    });

    Object.keys(props).forEach((k) => {
      s[k] = props[k];
      vars.push(k);
    });
    s.events = ''; // don't resend existing events
    s.linkTrackVars = vars.join(',');
    s.tl(true, 'o', linkName);
  },
  /* These are field updaters that originally had mappings. They now
   * do the same as "update" but without special handling of
   * approval */
  [updateContact]({ commit }, payload) {
    const { name, value } = payload;
    const np = { };
    np[name] = value;
    commit(update, np);
  },
  [updateField]({ commit }, payload) {
    const { name, value } = payload;
    const np = { };
    np[name] = value;
    commit(update, np);
  },
  [updateBilling]({ commit }, payload) {
    /* Unmapped field update */
    commit(updateBilling, payload);
  },
  [selectApprover]({ commit, dispatch, getters }, payload) {
    commit(selectApprover, payload);
    if (getters[approvalModified]) {
      dispatch(sendApprovers);
    }
  },
  [updateCheckStep]({ commit }, step) {
    commit(updateCheckStep, step);
  },
  [updateSchedule]({ commit }, payload) {
    commit(updateSchedule, payload);
  },
  [updateAddresses]({ commit, dispatch }, { use_otda, addresses }) {
    const selectedAddress = getters[shippingData];
    commit(updateAddresses, addresses);
    let sai = null;
    let address_selected = false;
    for (let i = 0; i < addresses.length; i += 1) {
      if (addresses[i].selected) {
        sai = i;
        address_selected = true;
        break;
      }
    }
    /* If nothing has been set, set it from the default */
    if (!use_otda && address_selected && !selectedAddress?.address1) {
      dispatch(updateSelectedAddressIndex, sai).then(() => {
        commit(resetUpdated);
        commit(resetCheckStep);
      });
    }
  },
  [updateSelectedAddressIndex]({ commit, getters }, index) {
    const allAddresses = getters[addresses];
    const newAddress = allAddresses[index];

    if (!newAddress) return;

    commit(updateSelectedAddressIndex, index);
    commit(update, {
      customerdescription: newAddress.description,
      address1: newAddress.address1,
      address2: newAddress.address2,
      city: newAddress.city,
      postcode: newAddress.postcode,
      state: newAddress.state,
      deliverynote: newAddress.deliverynote,
    });
    // Unset OneTime Address chosen
    commit(setOneTimeAddressChosen, false);
  },
  [updateSelectedAddressBySapId]({ getters, dispatch }, sap_address_id) {
    const allAddresses = getters[addresses];
    const addressEdited = allAddresses.find((obj) => obj.sap_address_id === sap_address_id);

    dispatch(updateSelectedAddressIndex, addressEdited.actualIndex);

    // Scroll to Edited Address
    const scrollDiv = document.getElementById('addressListScrollDiv');
    const scrollDivOffset = scrollDiv.offsetTop;
    const targetDiv = document.querySelector(`div[for="${addressEdited.actualIndex}"]`);
    const targetDivOffset = targetDiv.offsetTop;
    // 100 offset to move little down.
    scrollDiv.scrollTop = targetDivOffset - scrollDivOffset - 100;
  },
  [setOneTimeAddressChosen]({ commit, getters }, flag) {
    commit(setOneTimeAddressChosen, flag);
  },
};

const mutations = {
  [update](st, payload) {
    const server_update = payload.whencreated && true;
    Object.keys(payload).forEach((k) => {
      Vue.set(st, k, payload[k]);
      st.field_updated[k] = true;

      /* If this is a client update, advance the "check_step" to the
       * edited step, if it's past the user selected one. */
      if (!server_update) {
        const part = fmap[k];
        if (part) {
          const sec = part_section[part];
          if (sec > st.check_step) {
            st.check_step = sec;
          }
        }
      }
      //  need to know if we're showing postcode changed message
      if (!st.sentPostcodeChangedEvent
          && k === 'postcode'
          && payload[k] !== nxDatalayer?.global?.postcode) {
        st.sentPostcodeChangedEvent = true;
        doAdobeEvents({ event157: undefined }, {}, 'postcode changed at checkout');
      }
    });
    if (payload.apps) {
      st.apps.forEach(({ appid, uids, current }) => {
        // store the userid. If we store the index, it gets too hard
        if (isEmpty(st.apps_selection[appid])) {
          Vue.set(st.apps_selection, appid, '');
        }
        // make sure default apps are set
        if (uids.length === 1) {
          [current] = uids;
        }
        if (current > 0) {
          Vue.set(st.apps_selection, appid, current);
        }
      });
    }
  },
  [resetCheckStep](st) {
    st.check_step = 0;
  },
  [updateCheckStep](st, step) {
    st.check_step = step;
  },
  [setFocusStep](st, value) {
    st.focusStep = value;
  },
  [resetUpdated](st) {
    st.field_updated = {};
  },
  [resetAddressUpdated](st) {
    st.field_updated = {};
    const address_keys = ['address1', 'address2', 'city', 'state', 'postcode'];
    address_keys.forEach((akey) => {
      st.field_updated[`${akey}`] = false;
    });
  },
  [setDefaultReference](st) {
    if (st.cc.defaultreference && !st.customerreference) {
      st.customerreference = st.cc.defaultreference;
    }
  },
  [selectApprover](st, { id, value }) {
    const app = st.apps.find((elt) => elt.appid === id);
    const sec = part_section.approval;
    st.apps_selection[id] = value;
  },
  [updateBilling](st, payload) {
    const { billing } = st;
    Object.keys(payload).forEach((k) => {
      st.field_updated[`billing_${k}`] = true;
    });

    if (payload.sameas === true) {
      const billing_keys = ['address1', 'address2', 'city', 'state', 'postcode'];
      billing_keys.forEach((bkey) => {
        st.field_updated[`billing_${bkey}`] = true;
        payload[bkey] = st[bkey];
      });
    }

    Vue.set(st, 'billing', { ...billing, ...payload });
  },
  [updateSchedule](st, payload) {
    if (Object.prototype.hasOwnProperty.call(payload, 'forwarddate')) {
      st.forwarddate = payload.forwarddate;
    }
    if (payload.schedule) {
      const existing = st.schedule || {};
      Vue.set(st, 'schedule', { ...existing, ...payload.schedule });
    }
  },
  [orderInitialised](st) {
    st.initialised = true;
  },
  [updateAddresses](st, addresses) {
    st.addresses = addresses;
  },
  [updateSelectedAddressIndex](st, index) {
    st.selectedAddressIndex = index;
  },
  [setOneTimeAddressChosen](st, flag) {
    st.oneTimeAddressChosen = flag;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
