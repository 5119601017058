<template>
  <div>
    <updated-tip v-bind:message="errorMessage" v-bind:tipType="errorTipType" v-if="error" />
    <label v-bind:class="labelClass">
      <input type="checkbox"
        v-bind:checked="checked"
        v-bind:name="fieldName"
        v-bind:id="fieldId"
        v-on:change="updateFunc"
        v-bind:class="inputClass" />
      <span v-html="spanHTML"></span>
    </label>
  </div>
</template>

<script>
import UpdatedTip from './UpdatedTip';

export default {
  name: 'NxCheckbox',
  components: {
    'updated-tip': UpdatedTip,
  },
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      required: false,
    },
    errorMsg: {
      type: String,
      default: 'Please check this box',
      required: false,
    },
    errorTipType: {
      type: String,
      default: 'error smalltarget',
      required: false,
    },
    fieldId: {
      type: String,
      required: false,
    },
    fieldName: {
      type: String,
      required: false,
    },
    input_class: {
      type: String,
      default: 'checkout__payment__checkbox__input',
      required: false
    },
    input_err_class: {
      type: String,
      default: 'checkout__payment__checkbox__input checkout__payment__checkbox__input-invalid',
      required: false
    },
    isMandatory: {
      type: Boolean,
      required: true,
    },
    label_class: {
      type: String,
      default: 'checkout__payment__checkbox',
      required: false
    },
    label_err_class: {
      type: String,
      default: 'checkout__payment__checkbox checkout__payment__checkbox-invalid',
      required: false
    },
    labelText: {
      type: String,
      required: true,
    },
    updateFunc: {
      type: Function,
      required: true,
    },
  },
  computed: {
    errorMessage() {
      return this.error && this.errorMsg;
    },
    inputClass() {
      return this.error ? this.input_err_class : this.input_class;
    },
    labelClass() {
      return this.error ? this.label_err_class : this.label_class;
    },
    mandatoryFlag() {
      return this.isMandatory ? ' * ' : '';
    },
    spanHTML() {
      return `${this.mandatoryFlag} ${this.labelText}`;
    },
  },
};
</script>
