<template>
  <div>
    <div v-if="showFilter" class="checkout__shipping-address__pick-from-list__search">
        <input
          class="checkout__shipping-address__pick-from-list__search__input"
          placeholder="Search from existing Address"
          :value="searchTarget"
          v-on:input="updateSearch"
        />
        <div class="checkout__shipping-address__pick-from-list__search__icon-container">
          <div
            class="checkout__shipping-address__pick-from-list__search__icon-container__search-icon" /><!-- eslint-disable-line max-len -->
        </div>
    </div>

    <div id="addressListScrollDiv" class="checkout__shipping-address__pick-from-list">
      <div :class="showTwoCol && 'checkout__shipping-address__pick-from-list--two-col__list'">
      <div v-for="address in filteredAddresses" :key="address.actualIndex"
          data-mf-replace-inner="**NoMouseflow**"
          class="checkout__shipping-address__pick-from-list__radio-btn-wrapper">
          <AddressItem
            :isChecked="address.actualIndex === shippingAddressID"
            :handleClick="updateAddress(address.actualIndex)"
            :canEdit="canEdit"
            :editFunction="() => {setAddressToEdit(address)}"
            :canSelect="canSelect"
            :itemId="address.actualIndex.toString()"
            name="addressList"
          >
            <b v-if="address.description">
              {{address.description}}<br />
            </b>
            {{getFormattedAddress(address)}}
            <small><span class="deliveryNote">{{address.deliverynote}}</span></small>
            <p class="no-margin" v-if="showModified && address.modified">
              Last edited: {{address.modified}} by {{address.changedby}}
            </p>
          </AddressItem>
        </div>
      </div>
    </div>
    <EditAddress v-if="editingAddress"
      :cancelEdit="cancelAddressEdit"
      :saveAddressToEdit="saveAddressToSAP"
      :defaultAddress="addressToEdit"
      :alert="alert"
    />
  </div>
</template>
<script>

import { doAdobeEvents } from '@/helpers/adobe';
import AddressItem from './AddressItem.vue';
import EditAddress from './EditAddress.vue';

export default {
  name: 'AddressList',
  components: {
    AddressItem,
    EditAddress,
  },
  data() {
    return {
      editingAddress: false,
      addressToEdit: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postcode: '',
        deliverynote: '',
      },
      searchTarget: '',
    };
  },
  props: {
    addresses: {
      type: Array,
      default(rawProps) { return []; },
    },
    shippingAddressID: {
      type: Number,
      default: null,
    },
    updateAddress: {
      type: Function,
      default: () => {},
    },
    getFormattedAddress: {
      type: Function,
      default: () => {},
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    saveAddressToSAP: {
      type: Function,
      default: () => {},
    },
    showModified: {
      type: Boolean,
      default: false
    },
    alert: {
      type: String,
      default: '',
    },
    canSelect: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    showFilter() {
      return this.addresses.length > 10;
    },
    showTwoCol() {
      return this.addresses.length > 3;
    },
    filteredAddresses() {
      if (this.searchTarget === '') return this.addresses;
      const st = this.searchTarget.toUpperCase();
      return this.addressesForFilter.filter((el) => {
        return el.composite.indexOf(st) > -1;
      });
    },
    addressesForFilter() {
      return this.addresses.map((el) => {
        el.composite = `${el.description} ${el.address1} ${el.address2} ${el.state} ${el.postcode} ${el.city}`;
        el.composite = el.deliverynote ? `${el.composite} ${el.deliverynote}` : el.composite;
        el.composite = el.composite.toUpperCase();
        return el;
      }, []);
    },
  },
  methods: {
    updateSearch(ev) {
      this.searchTarget = ev.target.value;
    },
    setAddressToEdit(address) {
      this.addressToEdit = address;
      this.editingAddress = true;
      // adobe
      doAdobeEvents({ event134: undefined }, {}, 'Address update initiated');
    },
    cancelAddressEdit() {
      this.editingAddress = false;
    },
  },
  mounted() {
  },
};
</script>
