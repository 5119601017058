import isUndefined from 'lodash/isUndefined';
import { isEmpty } from '@/helpers/util';

const validMsg = '';
const space = ' ';

const getResult = (condition: boolean, failMessage = '') => (condition ? failMessage : '');
export const validate = (value, validators): string => {
  const failedValidator = validators.find((validator) => validator(value) !== validMsg);
  return isUndefined(failedValidator) ? validMsg : failedValidator(value);
};

export const valid = {
  at: (value) =>
    getResult(!value.includes('@'), 'must include an @'),
  noLetters: (value) =>
    getResult(value && value.split('').some((c) => { return (/[a-zA-Z]/).test(c); }),
      'must have no letters'),
  notEmpty: (value) =>
    getResult(isEmpty(value), 'cannot be empty'),
  fieldRequired: (fieldname) => (value) =>
    getResult(isEmpty(value), fieldname.concat(' is required')),
  boolean: (value) =>
    getResult(!(typeof (value) === 'boolean'), 'must be set'),
  numeric: (value) =>
    getResult(Number.isNaN(Number(value)), 'must be a number'),
  notZero: (value) => getResult(value === '0', 'cannot be zero'),
  minLength: (length = 1) => (value) =>
    getResult((!value) || value.length < length, 'too short'),
  maxLength: (length = 1) => (value) =>
    getResult(
      value?.length > length,
      `cannot be longer than ${length} characters`
    ),
  selected: (value = false) =>
    getResult(!value, 'please select'),
  space: (value) =>
    getResult(value.includes(' '), 'cannot include space'),

  fields: (st, fields, labels = {}, modified = {}, issues = {}) => {
    const errors = { valid: true };
    let all_valid = true;
    Object.keys(fields).forEach((f) => {
      const value = st[f];
      const error = validate(value, fields[f]);
      if (error) {
        const label = labels[f] || '';
        all_valid = false;
        errors[f] = `${label} ${error}`;
      } else if (issues[f] && !modified[f]) {
        all_valid = false;
        errors[f] = issues[f].message;
      }
    });
    errors.valid = all_valid;
    return errors;
  },
};

export const addressValidationRules = {
  address1: [
    valid.fieldRequired('Street address'),
    valid.maxLength(35),
  ],
  address2: [
    valid.maxLength(35),
  ],
  city: [
    valid.fieldRequired('City / suburb'),
    valid.maxLength(35),
  ],
  state: [valid.fieldRequired('State')],
  postcode: [
    valid.fieldRequired('Postcode'),
    valid.numeric,
    valid.maxLength(4),
    valid.minLength(3),
  ],
};

export const billingValidationRules = {
  billing_address1: [
    valid.notEmpty,
    valid.maxLength(35),
  ],
  billing_address2: [
    valid.maxLength(35),
  ],
  billing_city: [
    valid.notEmpty,
    valid.maxLength(35),
  ],
  billing_state: [valid.notEmpty],
  billing_postcode: [
    valid.notEmpty,
    valid.numeric,
    valid.maxLength(4),
  ],
};

export const notEmptyValidator = (value = '') => getResult(isEmpty(value), 'Cannot be empty');

export const numericValidator = (value = '') =>
  getResult(Number.isNaN(Number(value)), 'Must be a number');

export const minLengthValidator = (length = 0) => (value = '') =>
  getResult(value.length < length, 'Too short');

export const maxLengthValidator = (length = 0) => (value = '') => {
  getResult(value && value.length > length, `Cannot be over ${length} characters`);
};

export const spaceValidator = (value = '') => getResult(value.includes(space), 'Cannot have space');
export const isSelectedValidator = (value = false) => getResult(!value, 'Please select');
