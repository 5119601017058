<template>
  <div>
    <div class="checkout__shipping-address__one-time-shipping__lookup-help-message-wrapper">
      <div v-if="!isManual"
        class="checkout__shipping-address__one-time-shipping__retry-group">
        <b>Search for address</b>
      </div>
      <div v-else
        class="checkout__shipping-address__one-time-shipping__retry-group">
            <b>Address details</b>
            <button
              v-on:click="toggleManual"
              v-bind:class="lookupHelpButtonClass"
            >
            Try address finder
          </button>
      </div>
    </div>

    <div v-if="!isManual">
      <UpdatedTip message="Please search for an address and select from the search dropdown"
      tipType="error" v-if="lookUpHasError" />
      <UpdatedTip message="Select an address from the search dropdown or enter an address manually"
      tipType="error"
        v-if="notChosen" />
      <div
        data-mf-replace-inner="**NoMouseflow**"
        v-bind:class="lookupContainerClass"
      >
        <VueAutosuggest
          component-attr-id-autosuggest="checkout-one-time-shipping-auto-suggest"
          v-bind:inputProps="autosuggestProps"
          v-model="addressInput"
          v-bind:suggestions="addressList"
          v-bind:getSuggestionValue="getSuggestionValue"
          @selected="selectAddress"
          @input="getAddressList"
          @focusout="validateAddress">
          <template slot="after-suggestions">
            <ul role="listbox" v-if="allowManual">
              <li role="option"
                data-suggestion-index="-1"
                data-section-name="default"
                id="autosuggest__results-item---1"
                class="autosuggest__results-item"
                v-bind:class="lookupHelpButtonClass"
                style="text-align: left;">
                Can't find your address? Enter address manually
              </li>
            </ul>
          </template>
        </VueAutosuggest>
        <div class="checkout__shipping-address__one-time-shipping__lookup-container__search-icon-container"><!-- eslint-disable-line max-len -->
          <div class="checkout__shipping-address__one-time-shipping__lookup-container__search-icon-container__search-icon" /><!-- eslint-disable-line max-len -->
        </div>
      </div>
    </div>

    <div data-mf-replace-inner="**NoMouseflow**"
      v-if="getFormattedAddress.trim() !=='' && !isManual">
      <div >
        <p>
          <b v-if="addressDetails.address1" style="font-size:16px;">
            {{addressDetails.address1}}<br />
          </b>
          {{getFormattedAddress}}<br />
          <b><a href="javascript:void(0)"
            class="blue_underline_link"
            v-on:click="clearAddress">Clear address</a></b>
        </p>
      </div>
    </div>

    <div class="checkout__shipping-address__one-time-shipping__address-form" v-if="isManual">
      <!--div class="checkout__shipping-address__one-time-shipping__address-form__left
      checkout__shipping-address__one-time-shipping__address-form--half"-->
      <div class="fieldgrid">
        <div class="fieldgrid-50">
          <input type="hidden" name="manual"
            v-bind:value="addressDetails.manual"
            v-bind:handleChange="updateAddressField('manual')"
          />
          <InputGroup
            name="buildingSuiteFloor"
            label="Building / level / floor"
            maxLength="35"
            v-bind:id="`${idRoot}-building-suite-floor`"
            v-bind:handleChange="updateAddressField('address2')"
            v-bind:value="addressDetails.address2"
            v-bind:error="(isManual && errors.address2) || ''"
            v-bind:disabled="!isManual"
            v-bind:tickValid="tickValid"
            v-bind:handleBlur="sanitize('address2')"
          />
        </div>
        <div class="fieldgrid-50">
          <InputGroup
            name="address1"
            label="Street address *"
            maxLength="35"
            v-bind:id="`${idRoot}-address-line-1`"
            v-bind:handleChange="updateAddressField('address1')"
            v-bind:value="addressDetails.address1"
            v-bind:error="(isManual && errors.address1) || ''"
            v-bind:disabled="!isManual"
            v-bind:tickValid="tickValid"
            v-bind:handleBlur="sanitize('address1')"
          />
        </div>
        <div class="fieldgrid-50">
          <InputGroup
            label="City / suburb *"
            name="city"
            maxLength="35"
            v-bind:id="`${idRoot}-suburb`"
            v-bind:handleChange="updateAddressField('city')"
            v-bind:value="addressDetails.city"
            v-bind:error="(isManual && errors.city) || ''"
            v-bind:disabled="!isManual"
            v-bind:tickValid="tickValid"
            v-bind:handleBlur="sanitize('city')"
          />
        </div>
        <div class="fieldgrid-25">
          <InputGroup
            v-bind:id="`${idRoot}-state`"
            label="State *"
            name="'state'"
            placeholder="Select state"
            v-bind:type="2"
            v-bind:handleChange="updateAddressField('state')"
            v-bind:value="addressDetails.state"
            v-bind:error="(isManual && errors.state) || ''"
            v-bind:disabled="!isManual"
            v-bind:options="[
              { name: 'ACT', value: 'ACT' },
              { name: 'NSW', value: 'NSW' },
              { name: 'NT', value: 'NT' },
              { name: 'QLD', value: 'QLD' },
              { name: 'SA', value: 'SA' },
              { name: 'TAS', value: 'TAS' },
              { name: 'VIC', value: 'VIC' },
              { name: 'WA', value: 'WA' }
            ]"
            v-bind:tickValid="tickValid"
          />
        </div>
        <div class="fieldgrid-25">
          <InputGroup
            label="Postcode *"
            name="'postcode'"
            maxLength="4"
            v-bind:id="`${idRoot}-postcode`"
            v-bind:handleChange="updateAddressField('postcode')"
            v-bind:value="addressDetails.postcode"
            v-bind:error="(isManual && errors.postcode) || ''"
            v-bind:disabled="!isManual"
            v-bind:tickValid="tickValid"
            v-bind:handleBlur="sanitize('postcode')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';
import axios from 'axios';
import { isEmpty } from '@/helpers/util';
import InputGroup from '@/components/common/InputGroup';
import UpdatedTip from '@/components/common/UpdatedTip';
import { doAdobeEvents } from '@/helpers/adobe';
import { makeToast } from '../../../helpers/helpers';
import translateAddress from './translateAddress';

function fullTrim(v) {
  return v.replace(/\s+/g, ' ').trim();
}

export default {
  name: 'AddressWidget',
  components: {
    UpdatedTip,
    VueAutosuggest,
    InputGroup,
  },
  data() {
    return {
      localManual: false,
      addressList: [],
      addressInput: '',
      chosen: false,
      shownList: false,
      addressNotFound: false,
    };
  },
  props: {
    allowManual: {
      // true if the user can manually disable the adderss picker
      type: Boolean,
      default: true,
    },
    propManual: {
      // true if the widget should be in manual mode. optional, see propToggleManual
      type: Boolean,
      default: false,
    },
    propToggleManual: {
      // optional. IF this is passed, then we'll set
      // manual using this and propManual. Else, we'll
      // use the local value in data
      type: Function,
      default: null,
    },
    errors: {
      // This should be an object that holds any validation warnings
      // e.g. {"valid":false,"address1":" cannot be empty"}
      type: Object,
      default: () => {},
    },
    updateAddressField: {
      // Used to update a single address field
      // this should be a function that you call like
      // updateAddressField('address1') that returns a
      // curried function that will do the actual update
      type: Function,
      default: () => {},
    },
    updateFullAddress: {
      // this should be a function that will take an
      // object with each of the address fields set on it
      type: Function,
      default: () => {},
    },
    addressDetails: {
      // this holds the valus of the address fields
      type: Object,
      default: () => {
        return {
          address1: '',
          postcode: '',
          state: '',
          city: '',
          address2: '',
          manual: 0
        };
      },
    },
    tickValid: {
      // set to true if we should show a green tick for a valid, non-dirty entry
      type: Boolean,
      default: false,
    },
    idRoot: {
      type: String,
      default: 'addressWidget',
    },
  },
  computed: {
    isManual() {
      return this.propToggleManual === null
        ? this.localManual
        : this.propManual;
    },
    addressStatusUri() {
      return `${window.nxDatalayer.env.address_endpoint}account/getDailyAPICallCount`;
    },
    addressListUrl() {
      return `${window.nxDatalayer.env.address_endpoint}addresspro/autocomplete?term=`;
    },
    addressDetailsUrl() {
      return `${window.nxDatalayer.env.address_endpoint}addresspro/info?term=`;
    },
    addressTried() {
      return this.addressInput.length > 4;
    },
    notChosen() {
      return (!this.chosen) && (this.shownList);
    },
    lookupHelpTextClass() {
      if (this.addressTried) {
        return `checkout__shipping-address__one-time-shipping__lookup-help-message-plain
        checkout__shipping-address__one-time-shipping__lookup-help-message-plain--show`;
      }
      return 'checkout__shipping-address__one-time-shipping__lookup-help-message-plain';
    },
    lookupHelpButtonClass() {
      if (this.addressTried) {
        return `checkout__shipping-address__one-time-shipping__lookup-help-message
        checkout__shipping-address__one-time-shipping__lookup-help-message--show`;
      }
      return 'checkout__shipping-address__one-time-shipping__lookup-help-message';
    },
    lookupContainerClass() {
      if (this.lookUpHasError) {
        return `checkout__shipping-address__one-time-shipping__lookup-container
          checkout__shipping-address__one-time-shipping__lookup-container--error`;
      }
      return 'checkout__shipping-address__one-time-shipping__lookup-container';
    },
    supportPhone() {
      return window.nxDatalayer.env.support_phone;
    },
    getFormattedAddress() {
      return [
        this.addressDetails.address2,
        this.addressDetails.address1,
        this.addressDetails.city,
        `${this.addressDetails.state} ${this.addressDetails.postcode}`]
        .filter((elem) => typeof elem !== 'undefined' && elem !== '')
        .join(', ');
    },
    lookUpHasError() {
      if (this.addressNotFound) return false;

      if (isEmpty(this.errors)) return false;

      if (this.shownList && this.chosen) {
        return false;
      }

      return !this.errors.valid;
    },
    autosuggestProps() {
      const defaultPlaceholder = 'Search for an address (No PO Box or Locked Bags)';
      const thePlaceholder = this.getFormattedAddress.trim() || defaultPlaceholder;
      return {
        id: 'test-id',
        autofocus: true,
        placeholder: thePlaceholder,
        class: 'checkout__shipping-address__one-time-shipping__lookup-container__input'
      };
    },
  },
  methods: {
    sanitize(fieldName) {
      return () => {
        const cleaned = fullTrim(this.addressDetails[fieldName]);
        if (cleaned !== this.addressDetails[fieldName]) {
          // the update function always expects an event to extract the value from
          const fakeEvent = {
            target: {
              value: cleaned,
            },
          };
          this.updateAddressField(fieldName)(fakeEvent);
        }
      };
    },
    toggleManual() {
      doAdobeEvents({ event137: undefined }, {}, 'Address switch to manual');
      if (this.propToggleManual === null) {
        this.localManual = !this.localManual;
      } else {
        this.propToggleManual();
      }
    },
    addressError() {
      if (this.allowManual && this.isManual) {
        this.toggleManual();
      }
      makeToast({
        title: 'Address Lookup Service',
        text: 'There was a problem with the address lookup service. Please try again.',
        type: 'error',
        duration: -1,
      });
    },
    selectAddress(selected) {
      if (selected && selected.item !== 'Address not found') {
        const query = encodeURIComponent(selected.item);
        const uri = this.addressDetailsUrl + query;
        axios.get(uri)
          .then(({ data }) => {
            this.updateFullAddress(translateAddress(data));
            this.chosen = true;
          })
          .catch(() => {
            this.addressError();
          });
      } else if (selected === null) {
        this.chosen = false;
        this.toggleManual();
      }
    },
    getSuggestionValue(suggestion) {
      if (suggestion.item !== 'Address not found') {
        return suggestion.item;
      }

      return '';
    },
    getAddressList() {
      this.shownList = false;
      this.chosen = false;
      if (this.addressInput.length < 5) {
        this.addressList = [];
        return;
      }
      const query = encodeURIComponent(this.addressInput);
      const uri = this.addressListUrl + query;
      axios.get(uri)
        .then(({ data }) => {
          if (data.length === 0) {
            data.push('Address not found');
            this.addressNotFound = true;
            this.addressList = [{ data }];
            // Not show error since we show No Address Found item
            this.chosen = true;
          } else {
            this.addressNotFound = false;
            this.addressList = [{ data }];
          }

          this.shownList = true;
        })
        .catch(() => {
          this.addressError();
        });
    },
    clearAddress() {
      this.chosen = false;
      this.updateFullAddress({
        address1: '',
        postcode: '',
        state: '',
        city: '',
        address2: '',
      });
      this.addressInput = '';
    },
    validateAddress() {
      if (this.addressNotFound) {
        this.chosen = false;
      }
    },
  },
  mounted() {
    axios.get(this.addressStatusUri)
      .catch(() => {
        this.addressError();
      });
  }
};
</script>
