<template>
  <div class="field-group">
    <label>{{ label }}</label>
    <UpdatedTip
        :message="errorMessage"
        tipType="error"
        v-if="errorMessage" />
    <input type="file" :id="id" style="display:none" :ref="id" @change="selectFile" />
    <div class="spaceddiv" v-if="showButton">
      <button type="button" @click="myClickUpload" v-html="buttonText"
        class="wc-button wc-button--secondary"/>
    </div>
    <label>{{ helpText }}</label>
    <div v-if="attachments.length > 0">
      <attachment
        v-for="att in trimmedAttachments"
        :key="att.filename"
        :attachment="att"
        :delete="() => deleteFile(att)"
        :readOnly="!editing"
      />
    </div>
  </div>
</template>

<script>
import Attachment from '@/components/common/NxUploadControl/Attachment.vue';
import UpdatedTip from '@/components/common/UpdatedTip';

// file type/size/name validation should be done in the prop selectFile() function
export default {
  name: 'NxUpload',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    selectFile: {
      type: Function,
      required: true,
    },
    editing: {
      type: Boolean,
      default: true,
    },
    clickUpload: {
      type: Function,
      default: () => {},
    },
    buttonText: {
      type: String,
      default: 'Upload',
    },
    attachments: {
      type: Array,
      required: true,
      default: () => [],
    },
    deleteFile: {
      type: Function,
      required: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    maxUploads: { // -1 is no limit
      type: Number,
      default: -1,
    },
  },
  data: function data() {
    return {
    };
  },
  components: {
    Attachment,
    UpdatedTip
  },
  computed: {
    showButton() {
      return this.editing && (this.maxUploads === -1 || this.attachments.length < this.maxUploads);
    },
    trimmedAttachments() {
      // never show more than max attachments
      if (this.maxUploads > 0) return this.attachments.slice(0, this.maxUploads);
      return this.attachments;
    }
  },
  methods: {
    myClickUpload() {
      this.$refs[this.id].value = '';
      this.$refs[this.id].click();
      this.clickUpload();
    },
  },
};
</script>
