export const redirectOnAuthFail = (error, url) => {
  if (error?.response?.status === 403) {
    if (url) {
      document.location.href = url;
      return;
    }
    if (window.nx?.login?.login_lightbox) {
      nx.login.login_lightbox();
      return;
    }
    window.location.href = '/main-my-login';
  } else if (error?.response?.status === 405) {
    /* Customer's order, but not editable - take them to checkout */
    window.location.href = '/main-orders-viewcurrent';
  }
};
