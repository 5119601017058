<template>

  <div class="account_chooser">
    <div :id="divId">
      <label :for="fieldId" class="checkout__label">
        Account
      </label>
      <NxComboBox
        :inputValue="searchVal"
        :updateValue="updateSearch"
        :items="searchListFiltered"
        :selectedIndex = "selectedIndex"
        :selectValue="selectValue"
        :placeholder="placeholder"
        :fieldId="fieldId"
        containerClass="account_chooser"
        listClass="cost-centre-select-list"
        ikey="ccode"
      >
        <template v-slot:item="slotProps">
          <li :class="liClass(slotProps)"
            :aria-selected="slotProps.item.ccode == curr_ccode"
            :id="`${fieldId}_li_${slotProps.index}`"
            >
            <div class="select-item-marker">
              <i class="fa fa-dot-circle-o" v-if="slotProps.item.ccode == curr_ccode"></i>
              <i class="fa fa-dot-circle" v-else></i>
            </div>
            <div class="select-item-label">{{ slotProps.item.ccode }}</div>
            <div class="select-item-description">{{ slotProps.item.acc_desc }}</div>
            <div class="select-item-label">{{ slotProps.item.customercode }}</div>
          </li>
        </template>
      </NxComboBox>
    </div>
  </div>
</template>

<script>

import NxComboBox from '@/components/common/NxComboBox.vue';

export default {
  name: 'NxAccounts',
  props: {
    ccs: {
      type: Array,
      required: true,
    },
    curr_ccode: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    accountChanged: {
      type: Function,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    divId: {
      type: String,
      required: true,
    }
  },
  data: function data() {
    const cc = this.ccs.find((item) => item.ccode === this.curr_ccode);
    return {
      searchVal: (cc && cc.name) || '',
    };
  },
  components: {
    NxComboBox,
  },
  computed: {
    searchListFiltered() {
      const searchVal = this.searchVal.toLowerCase();
      return this.ccs.filter((item) => {
        return item.ccode.toLowerCase().includes(searchVal)
          || item.acc_desc.toLowerCase().includes(searchVal)
          || item.customercode.toLowerCase().includes(searchVal);
      });
    },
    selectedIndex() {
      return this.searchListFiltered.findIndex((item) => item.ccode === this.curr_ccode);
    },
  },
  methods: {
    updateSearch(value) {
      this.searchVal = value;
    },
    selectValue(val) {
      this.searchVal = val.name;
      this.accountChanged(val);
    },
    liClass(slotProps) {
      if (slotProps.item.ccode === this.curr_ccode) return 'select-item selected';
      if (slotProps.navSelected) return 'select-item select-item--nav-selected';
      return 'select-item';
    },
  },
};
</script>
