import { InputType, ValidationStatus } from '../types';

const getValidationClassName = (status) => {
  switch (status) {
    case ValidationStatus.Passed: {
      return 'checkout__input-group__input--success';
    }
    case ValidationStatus.Failed: {
      return 'checkout__input-group__input--error';
    }
    case ValidationStatus.Search: {
      return 'input-group__input-search';
    }
    default: {
      return '';
    }
  }
};

const Input = {
  functional: true,
  render(
    createElement,
    {
      props: {
        id,
        type = InputType.Text,
        handleChange,
        fieldtype,
        validationStatus,
        value,
        placeholder,
        inputClassName,
        disabled,
        options = [],
        maxLength,
        showDollar,
        handleBlur,
      }
    }
  ) {
    const errorClassName = getValidationClassName(validationStatus);
    const indentClass = showDollar ? 'checkout__input-group__input--indent' : '';
    const onBlurFunction = handleBlur || (() => {});
    switch (type) {
      case InputType.TextArea: {
        return (
          <textarea
            id={id}
            class={`no-mouseflow checkout__input-group__input--textarea ${inputClassName} ${errorClassName} $`}
            onInput={handleChange}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            maxlength={maxLength}
          />
        );
      }
      case InputType.Select: {
        return (
          <select
            id={id}
            class={`no-mouseflow checkout__select ${inputClassName} ${errorClassName}`}
            onChange={handleChange}
            onFocusout={handleChange}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map(({ name, value }) => (
              <option value={value}>{name}</option>
            ))}
          </select>
        );
      }
      default: {
        return (
          <input
            id={id}
            class={`no-mouseflow checkout__input-group__input ${indentClass} ${inputClassName} ${errorClassName}`}
            onInput={handleChange}
            onFocusout={handleChange}
            type={fieldtype}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            maxlength={maxLength}
            onBlur={onBlurFunction}
          />
        );
      }
    }
  }
};

export default Input;
