import { Coupon } from '@/components/Checkout/OrderSummary/store';

interface CouponInResponse {
  item: {
    packsize: string;
    description: string;
    prodcode: string;
    primary_image: {
      thumb: string;
    }
  }
}

export const getCoupons = (coupons: CouponInResponse[]): Coupon[] =>
  coupons.map(
    ({
      item
    }) => ({
      description: item.description,
      prodCode: item.prodcode,
      packSize: item.packsize,
      imgUrl: item.primary_image.thumb,
    })
  );
