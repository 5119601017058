<template>
  <div v-if="showAccountInfo" class="cart__details__account">
    <b>{{displayLabel}}:</b><br />
    <span v-if="canChangeCC">
      <a class="account-picker-link blue_underline_link"
        v-bind:title="displayLabel" href="#"
        onclick="nx.selectCC.select_cc_lightbox(event);">{{displayName}}</a>
    </span>
    <span v-else>
      {{displayName}}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ccInfo,
} from './OrderDetailsStore';

export default {
  name: 'OrderDetailsAccountPicker',
  props: {
    readOnly: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      ccInfo,
    }),
    showAccountInfo() {
      return !window.nxDatalayer.global.hide_account_info;
    },
    canChangeCC() {
      return window.nxDatalayer.global.can_change_costcentre && !this.readOnly;
    },
    displayLabel() {
      if (!Array.isArray(this.ccInfo.display)) { return ''; }
      return this.ccInfo.display[0];
    },
    displayName() {
      if (!Array.isArray(this.ccInfo.display)) { return this.ccInfo.description; }
      return `${this.ccInfo.display[1]} - ${this.ccInfo.description}`;
    },
  },
};
</script>
