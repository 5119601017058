import { invoiceEndpoint, emailInvoiceEndpoint, ccInvoiceEndpoint } from '../mocks';

export class NxData {
  isMocking;

  nxDatalayer;

  constructor() {
    this.isMocking = window.isMocking;
    this.nxDatalayer = window.nxDatalayer;
  }

  nxUrl(type) {
    let nxUrl;
    if (type === 'invoice') {
      nxUrl = this.isMocking ? invoiceEndpoint : this.nxDatalayer.env.invoice_endpoint;
    } else if (type === 'invoice_email') {
      nxUrl = this.isMocking ?
        emailInvoiceEndpoint :
        `${this.nxDatalayer.env.invoice_endpoint}/email`;
    } else if (type === 'invoice_cciframe') {
      nxUrl = this.isMocking ?
        ccInvoiceEndpoint :
        `${this.nxDatalayer.env.invoice_endpoint}/cciframe`;
    } else if (type === 'invoice_payrecord') {
      nxUrl = this.isMocking ?
        ccInvoiceEndpoint :
        `${this.nxDatalayer.env.invoice_endpoint}/payrecord`;
    } else if (type === 'invoice_paynow') {
      nxUrl = this.isMocking ?
        ccInvoiceEndpoint :
        `${this.nxDatalayer.env.invoice_endpoint}/paynow`;
    } else if (type === 'invoice_paycancel') {
      nxUrl = this.isMocking ?
        ccInvoiceEndpoint :
        `${this.nxDatalayer.env.invoice_endpoint}/paycancel`;
    } else if (type === 'invoice_payreceipt') {
      nxUrl = this.isMocking ?
        ccInvoiceEndpoint :
        `${this.nxDatalayer.env.invoice_endpoint}/payreceipt`;
    }
    return nxUrl;
  }
}
