import Vue from 'vue';

/** *******************************
 * helpers - action names
 ******************************* */
export const updateOrderDetails = '@orderDetails/actions/updateOrderDetails';

/** *******************************
 * helpers - getter names
 ******************************* */
export const orderid = '@orderDetails/getters/orderid';
export const cost = '@orderDetails/getters/cost';
export const SOSC = '@orderDetails/getters/SOSC';
export const ccInfo = '@orderDetails/getters/ccInfo';
export const readOnly = '@orderDetails/getters/readOnly';
export const coupons = '@orderDetails/getters/coupons';
export const simulationError = '@orderDetails/getters/simulationError';

/** *******************************
 * helpers - utils
 ******************************* */

/** *******************************
 * state
 ******************************* */
const state = {
  orderid: '',
  readOnly: true,
  cost: {},
  SOSC: false,
  simulationError: false,
  ccInfo: {},
  coupons: [],
};

/** *******************************
 * getters
 ******************************* */
const getters = {
  [orderid]: (st) => st.orderid,
  [cost]: (st) => st.cost,
  [SOSC]: (st) => st.SOSC,
  [simulationError]: (st) => st.simulationError,
  [ccInfo]: (st) => st.ccInfo,
  [readOnly]: (st) => st.readOnly,
  [coupons]: (st) => st.coupons,
};

/** *******************************
 * mutations
 ******************************* */
const mutations = {
  [updateOrderDetails](st, payload) {
    st.orderid = payload.orderid;
    st.cost = payload.cost;
    st.ccInfo = payload.cc;
    // st.SOSC = payload?.cost?.charge?.label === 'Small Order Service Charge';
    st.SOSC = payload.issues.find((el) => el.code === 'small_order_surcharge');
    st.simulationError = payload.issues.find((el) => el.code === 'simulation_error');
    st.readOnly = !payload?.permissions?.editable;
    st.coupons = payload.coupons || [];
  },
};

/** *******************************
 * actions
 ******************************* */
const actions = {
  [updateOrderDetails]({ commit }, payload) {
    commit(updateOrderDetails, payload);
  },
};

const orderDetailsModule = {
  state,
  mutations,
  actions,
  getters
};
export default orderDetailsModule;
