<template>
  <div :class="containerClass">
    <input
      type="text"
      @input="typeValue"
      @keydown="keydown"
      @blur="blurControl"
      @focus="focus"
      :value="inputValue"
      :placeholder="placeholder"
      :id="fieldId"
      aria-autocomplete="both"
      :aria-controls="boxId"
      :aria-expanded="hasFocus"
    /><span class="account__chevron">
      <i class="fa-solid fa-chevron-down" tabindex="-1"></i>
    </span>
    <ul :class="listClass" v-if="hasFocus" :id="boxId" :aria-label="placeholder">
      <li
        v-for="item, index in items"
        :key="item[ikey]"
        @click="select(item)">
          <slot
            name="item"
            :item="item"
            :navSelected="navIndex===index"
            :index="index"></slot>
        </li>
        <slot name="no-results" v-if="items.length === 0"></slot>
        <slot name="end-results" v-if="items.length !== 0"></slot>
    </ul>
  </div>
</template>

<script>
// item slot is mandatory
// no-results and end-results slots are optional
// example use
/* <NxComboBox
    :inputValue="searchVal"
    :updateValue="updateSearch"
    :items="searchListFiltered"
    :selectValue="selectValue"
    :placeholder="placeholder"
    :fieldId="fieldId"
    containerClass="account_chooser"
    listClass="cost-centre-select-list"
    ikey="ccode"
  >
    <template v-slot:item="slotProps">
      <li :class="slotProps.item.ccode == curr_ccode ? 'select-item selected' : 'select-item'" >
        <div class="select-item-marker">
          <i class="fa fa-dot-circle-o" v-if="slotProps.item.ccode == curr_ccode"></i>
          <i class="fa fa-dot-circle" v-else></i>
        </div>
        <div class="select-item-label">{{ slotProps.item.ccode }}</div>
        <div class="select-item-description">{{ slotProps.item.acc_desc }}</div>
        <div class="select-item-label">{{ slotProps.item.customercode }}</div>
      </li>
    </template>
    <template v-slot:no-results>no results</template>
    <template v-slot:end-results><li class="select-item">end resrrser</li></template>
  </NxComboBox> */
import { nextTick } from 'vue';

const scrollToSelected = (boxId, itemclass) => {
  const box = document.querySelector(`#${boxId}`);
  const selectedItem = document.querySelector(`#${boxId} .${itemclass}`);
  if (selectedItem) {
    box.scrollTop = selectedItem.offsetTop - box.offsetTop;
  }
};

export default {
  name: 'NxComboBox',
  data: function data() {
    return {
      hasFocus: false,
      navIndex: -1,
    };
  },
  components: {
  },
  props: {
    inputValue: {
      type: String,
      required: true,
    },
    updateValue: {
      type: Function,
      required: true,
    },
    selectValue: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectedIndex: {
      type: Number,
      default: -1,
    },
    placeholder: {
      type: String,
      default: '',
    },
    ikey: {
      type: String,
      default: 'ccode',
    },
    fieldId: {
      type: String,
      required: true,
    },
    containerClass: {
      type: String,
      default: 'account_chooser',
    },
    listClass: {
      type: String,
      default: 'cost-centre-select-list',
    },
  },
  computed: {
    boxId() {
      return `${this.fieldId}-box`;
    },
  },
  methods: {
    select(item) {
      this.hasFocus = false;
      this.selectValue(item);
    },
    focus() {
      this.updateValue('');
      this.hasFocus = true;
      nextTick(() => {
        this.navIndex = this.selectedIndex;
        scrollToSelected(this.boxId, 'select-item.selected');
      });
    },
    keydown(e) {
      if (e.key === 'ArrowDown') {
        if (this.navIndex < this.items.length - 1) {
          this.navIndex += 1;
        }
      } else if (e.key === 'ArrowUp') {
        if (this.navIndex > 0) {
          this.navIndex -= 1;
        }
      } else if (e.key === 'Enter') {
        if (this.navIndex > -1) {
          this.select(this.items[this.navIndex]);
          this.navIndex = -1;
          e.target.blur();
        }
      } else if (e.key === 'Escape') {
        this.navIndex = -1;
        e.target.blur();
      }
      if (this.navIndex > -1) {
        nextTick(() => {
          scrollToSelected(this.boxId, 'select-item--nav-selected');
        });
      }
    },
    typeValue(e) {
      this.navIndex = -1;
      this.updateValue(e.target.value);
    },
    blurControl() {
      setTimeout(() => { this.hasFocus = false; }, 200);
    },
  },
};
</script>
